/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect } from 'react';
import { XIcon } from '@heroicons/react/outline';
import router from 'next/router';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';
import ConnectTwitter from '../../Modal/connect-twitter';
import ReconnectTwitter from '../../Modal/reconnect-twitter';
import EssayCard from '../../Cards/essayCard';

import { RadioGroup } from '@headlessui/react';
import Button from '../../Button';
import toast, { Toaster } from 'react-hot-toast';

import { Listbox, Transition, Dialog } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { client } from '../../../utils/http';

const linkCardTags = [
  { id: 1, name: 'Link' },
  { id: 2, name: 'Product' },
  { id: 3, name: 'Article' },
  { id: 4, name: 'Podcast' },
  { id: 5, name: 'Pin' },
  { id: 6, name: 'Song' },
  { id: 7, name: 'Book' },
  { id: 8, name: 'Website' },
  { id: 9, name: 'Portfolio' },
  { id: 10, name: 'NFT' },
  { id: 11, name: 'Video' },
  { id: 12, name: 'Image' },
  { id: 13, name: 'Profile' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function LinkCardEditor({
  open,
  setOpen,
  card,
  refreshCards,
  loggedInUserID,
  slug,
  passDimensions,
}) {
  const cancelButtonRef = useRef();

  const [selected, setSelected] = useState(linkCardTags[0]);

  const [title, setTitle] = useState('');
  const [background, setBackground] = useState(0);
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [activeKey, setActiveKey] = useState(null);
  const [hasContent, setHasContent] = useState(false);
  const [image, setImage] = useState('');
  const [tag, setTag] = useState('Link');
  const [errorMSG, setErrorMSG] = useState('');
  const [IMGLoading, setIMGLoading] = useState(false);

  const [twitterToken, setTwitterToken] = useState('');
  const [twitterSecret, setTwitterSecret] = useState('');

  const [fetchingData, setFetchingData] = useState(false);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getToken() {
    var ref = firebase
      .database()
      .ref('users/' + loggedInUserID + '/meta/twitter');
    ref.once(
      'value',
      function (snapshot) {
        if (snapshot.exists()) {
          setTwitterSecret(snapshot.val().secret);
          setTwitterToken(snapshot.val().token);
        }
      },
      function (errorObject) {},
    );
  }

  useEffect(() => {
    loggedInUserID ? getToken() : null;
  }, [loggedInUserID]);

  useEffect(() => {
    if (card !== null) {
      if (card.hasChild('content')) {
        setHasContent(true);
        setImage(
          card.hasChild('content/image') ? card.val().content.image : null,
        );
        setTitle(
          card.hasChild('content/title') ? card.val().content.title : null,
        );
        setDescription(
          card.hasChild('content/description')
            ? card.val().content.description
            : null,
        );
        setLink(card.hasChild('content/link') ? card.val().content.link : null);
        setTag(card.hasChild('content/tag') ? card.val().content.tag : null);
      } else {
        setHasContent(false);
        setLink('');
      }

      setSelected(null);

      setErrorMSG('');
      setActiveKey(card.key);
    }
  }, [card]);

  const saveLinkCard = () => {
    firebase
      .database()
      .ref('gallery/' + slug + '/' + loggedInUserID + '/blog/cards/')
      .child(activeKey)
      .child('content')
      .update({
        title,
        description,
        link,
        image,
        tag,
      })
      .then(() => {
        refreshCards();
        setOpen(false);
      });
  };

  const generateLinkCard = (
    title,
    description,
    link,
    image,
    tag,
    favicon,
    isb64 = false,
  ) => {
    if (isb64) {
      const dbRef = firebase
        .database()
        .ref('gallery/' + slug + '/' + loggedInUserID + '/blog/cards/')
        .child(activeKey)
        .child('content')
        .child('image');

      var storageRef = firebase.storage().ref();
      var upload = storageRef
        .child(loggedInUserID)
        .child('socialBlog')
        .child('linkImages')
        .child(activeKey)
        .putString(image, 'base64');

      upload
        .then((snapshot) => {
          var imgRef = storageRef
            .child(loggedInUserID)
            .child('socialBlog')
            .child('linkImages')
            .child(activeKey);

          imgRef.getDownloadURL().then((url) => {
            firebase
              .database()
              .ref('gallery/' + slug + '/' + loggedInUserID + '/blog/cards/')
              .child(activeKey)
              .child('content')
              .update({
                title,
                description,
                link,
                tag: capitalizeFirstLetter(tag),
                image: url,
                favicon,
              })
              .then(() => {
                setFetchingData(false);
                refreshCards();
                setIMGLoading(false);
                // setOpen(false);
              });
          });
        })
        .catch((error) => {
          setIMGLoading(false);
          setImage('');
          alert('Something went wrong with uploading your image!');
        });
    } else {
      firebase
        .database()
        .ref('gallery/' + slug + '/' + loggedInUserID + '/blog/cards/')
        .child(activeKey)
        .child('content')
        .update({
          title,
          description,
          link,
          tag: capitalizeFirstLetter(tag),
          image,
          favicon,
        })
        .then(() => {
          setFetchingData(false);
          refreshCards();
          setIMGLoading(false);
          // setOpen(false);
        });
    }
  };

  async function getBase64Image(img, data) {
    console.log('getBase64Image');

    // This can be downloaded directly:
    try {
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        var blob = xhr.response;
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async function () {
          var base64data = reader.result.replace(
            'data:application/octet-stream;base64,',
            '',
          );
          var newImg = base64data
            .replace('data:image/jpeg;base64,', '')
            .replace('data:image/png;base64,', '')
            .replace('data:image/jpg;base64,', '')
            .replace('data:image/gif;base64,', '')
            .replace('data:image/webp;base64,', '')
            .replace('data:image/svg+xml;base64,', '')
            .replace('data:image/bmp;base64,', '')
            .replace('data:image/tiff;base64,', '')
            .replace('data:image/x-icon;base64,', '');

          //                    console.log(data, "data")
          generateLinkCard(
            data.meta.title ? data.meta.title : '',
            data.meta.description ? data.meta.description : '',
            link,
            newImg,
            data.meta.tag,
            data.meta.favicon,
            true,
          );

          return newImg;
        };
      };

      function transferFailed(evt) {
        console.log('An error occurred while transferring the file.');
        generateLinkCard(
          data.meta.title ? data.meta.title : '',
          data.meta.description ? data.meta.description : '',
          link,
          img,
          data.meta.tag,
          data.meta.favicon,
          false,
        );
        // Do something
      }

      xhr.open('GET', img);
      xhr.addEventListener('error', transferFailed);
      xhr.send();
    } catch (error) {
      console.log('ERRRRR', error);
    }
  }

  async function getLinkData() {
    //check if the input link is a valid url
    if (link.includes('https://') || link.includes('http://')) {
      setFetchingData(true);
      const url = '/api/social-blog/link-preview';
      try {
        const { data } = await client.post('/api/social-blog/link-preview', {
          url: link,
          twitter: { token: twitterToken, secret: twitterSecret },
        });
        if (!data.success) {
          throw new Error('failed to get link preview');
        }
        var newIMG = '';
        data.meta.title ? setTitle(data.meta.title) : setTitle('');
        data.meta.description
          ? setDescription(data.meta.description)
          : setDescription('');
        data.meta.image ? setImage(data.meta.image) : setImage('');
        data.meta.tag ? setTag(data.meta.tag) : setTag('');

        if (data.meta.image) {
          getBase64Image(data.meta.image, data);
          //upload img to firebase storage
        } else {
          setFetchingData(false);
          generateLinkCard(
            data.meta.title ? data.meta.title : '',
            data.meta.description ? data.meta.description : '',
            link,
            newIMG,
            data.meta.tag,
            data.meta.favicon,
          );
        }
      } catch (err) {
        console.error(err);
        problemToast('There was an error fetching the link preview');
        setHasContent(true);
        setTitle('');
        setDescription('');
        setSelected(null);
        setImage('');
        setTag('');
        setErrorMSG("Couldn't create an automatic link preview");
        setFetchingData(false);
      }
    } else {
      setErrorMSG(
        'Please enter a valid link with https:// or http:// in front',
      );
    }
  }

  const successToast = (text) =>
    toast.success(text, {
      id: 'success',
      /* icon: '🪄', */
      style: {
        borderRadius: '100px',
        background: '#0F172A',
        color: '#fff',
      },
    });

  const problemToast = (text) =>
    toast.error(text, {
      id: 'error',
      /* icon: '🪄', */
      style: {
        borderRadius: '100px',
        background: '#0F172A',
        color: '#fff',
      },
    });

  async function uploadImage(event) {
    setIMGLoading(true);

    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 2097152) {
        alert('Maximum image size is 2MB');
      } else {
        let img = event.target.files[0];
        setImage(URL.createObjectURL(event.target.files[0]));
        addImagetoDB(img);
      }
    }
  }

  const resetUpload = () => {
    const db = firebase.database();
    db.ref('gallery/' + slug + '/' + loggedInUserID + '/blog/cards/')
      .child(activeKey)
      .child('content')
      .child('image')
      .remove()
      .then(() => {
        setImage('');
      });
  };

  async function addB64ImagetoDB(image) {
    const dbRef = firebase
      .database()
      .ref('gallery/' + slug + '/' + loggedInUserID + '/blog/cards/')
      .child(activeKey)
      .child('content')
      .child('image');

    var storageRef = firebase.storage().ref();
    var upload = storageRef
      .child(loggedInUserID)
      .child('socialBlog')
      .child('linkImages')
      .child(activeKey)
      .putString(image, 'base64');

    upload
      .then((snapshot) => {
        var imgRef = storageRef
          .child(loggedInUserID)
          .child('socialBlog')
          .child('linkImages')
          .child(activeKey);

        imgRef.getDownloadURL().then((url) => {
          dbRef.set(url).then(() => {
            setIMGLoading(false);
            return true;
          });
        });
      })
      .catch((error) => {
        setIMGLoading(false);
        setImage('');
        alert('Something went wrong with uploading your image!');
      });
  }

  async function addImagetoDB(image) {
    const dbRef = firebase
      .database()
      .ref('gallery/' + slug + '/' + loggedInUserID + '/blog/cards/')
      .child(activeKey)
      .child('content')
      .child('image');

    var storageRef = firebase.storage().ref();
    var upload = storageRef
      .child(loggedInUserID)
      .child('socialBlog')
      .child('linkImages')
      .child(activeKey)
      .put(image);

    upload
      .then((snapshot) => {
        var imgRef = storageRef
          .child(loggedInUserID)
          .child('socialBlog')
          .child('linkImages')
          .child(activeKey);

        imgRef.getDownloadURL().then((url) => {
          dbRef.set(url).then(() => {
            setIMGLoading(false);
            return true;
          });
        });
      })
      .catch((error) => {
        setIMGLoading(false);
        setImage('');
        alert('Something went wrong with uploading your image!');
      });
  }

  return (
    <>
      <Transition.Root initialFocus={cancelButtonRef} show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          open={open}
          onClose={setOpen}
        >
          <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center antialiased sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-md backdrop-filter transition-all" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-24 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-24 sm:scale-95"
            >
              <div
                className={
                  'relative inline-block transform overflow-hidden rounded-2xl bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:align-middle'
                }
              >
                <button
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                  className={
                    'absolute top-2 right-2 transform rounded-full border border-gray-200 p-1.5 text-gray-700 outline-none duration-200 hover:-translate-y-0.5 hover:text-gray-900 hover:outline-none focus:outline-none'
                  }
                >
                  <svg className={'h-5 w-5'} fill="none" viewBox="0 0 24 24">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M17.25 6.75L6.75 17.25"
                    ></path>
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M6.75 6.75L17.25 17.25"
                    ></path>
                  </svg>
                </button>

                <div
                  className={'flex flex-col items-start justify-center pt-8'}
                >
                  <Dialog.Title
                    as="h3"
                    className="mb-8 px-8 text-left font-primary text-3xl font-bold text-gray-900 "
                  >
                    Edit Link Card
                  </Dialog.Title>

                  <div
                    className={
                      'flex w-full flex-col items-center justify-center space-y-6 px-8 pb-8'
                    }
                  >
                    <div className={'flex w-full flex-col space-y-1'}>
                      <div className={'flex flex-col'}>
                        <p
                          className={
                            'font-primary text-lg font-semibold text-gray-900'
                          }
                        >
                          Link
                        </p>
                        <p
                          className={
                            'font-sans text-sm font-medium text-gray-500'
                          }
                        >
                          Where should we send people who click on this card?
                        </p>
                      </div>
                      <div className="mt-1 w-full">
                        <input
                          placeholder={'https://typeshare.co'}
                          value={link}
                          onChange={(event) => setLink(event.target.value)}
                          className={
                            'w-full rounded-lg border-2 border-gray-200 bg-white p-3 font-medium transition duration-200 focus:border-gray-900 focus:outline-none focus:ring-0'
                          }
                        />
                      </div>
                    </div>
                    {hasContent ? (
                      <>
                        <div className={'flex w-full flex-col space-y-1'}>
                          <p
                            className={
                              'font-primary text-lg font-semibold text-gray-900'
                            }
                          >
                            Title
                          </p>
                          <div className="mt-1 w-full">
                            <input
                              value={title}
                              placeholder={'My Card Title'}
                              onChange={(event) => setTitle(event.target.value)}
                              className={
                                'w-full rounded-lg border-2 border-gray-200 bg-white p-3 font-medium transition duration-200 focus:border-gray-900 focus:outline-none focus:ring-0'
                              }
                            />
                          </div>
                        </div>

                        <div className={'flex w-full flex-col space-y-1'}>
                          <p
                            className={
                              'font-primary text-lg font-semibold text-gray-900'
                            }
                          >
                            Tag
                          </p>
                          <div className="mt-1 w-full">
                            <select
                              id="location"
                              name="location"
                              className={
                                'w-full rounded-lg border-2 border-gray-200 bg-white p-3 font-medium transition duration-200 focus:border-gray-900 focus:outline-none focus:ring-0'
                              }
                              defaultValue={tag}
                              value={tag}
                              onChange={(event) => setTag(event.target.value)}
                            >
                              {linkCardTags.map((singleTag) => (
                                <option
                                  value={singleTag.name}
                                  key={singleTag.id}
                                >
                                  {singleTag.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className={'flex w-full flex-col space-y-1'}>
                          <p
                            className={
                              'font-primary text-lg font-semibold text-gray-900'
                            }
                          >
                            Image
                          </p>
                          {/* <div className="mt-1 w-full border-gray-200 border-2 flex items-center justify-center rounded-lg p-4 bg-gray-100">
                                                <img className='h-64 rounded-lg' src={image} />
                                            </div> */}

                          <label
                            htmlFor="file-upload"
                            className="relative flex cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-200 bg-gray-100 p-8 font-primary text-base font-semibold"
                          >
                            <button
                              onClick={() => resetUpload()}
                              className={` ${
                                image ? 'flex' : 'hidden'
                              } absolute top-2 right-2 transform rounded-full bg-red-500 bg-opacity-10 p-1.5 text-red-500 duration-200 hover:scale-110 active:scale-105`}
                            >
                              <XIcon className={'h-5 w-5'} />
                            </button>

                            <input
                              id="image-upload"
                              onChange={(event) => uploadImage(event)}
                              name="file-upload"
                              type="file"
                              className="sr-only"
                            />
                            <img
                              src={image}
                              id="medium-story-preview"
                              className={` ${
                                image ? 'flex' : 'hidden'
                              } h-64 w-auto rounded-3xl`}
                            />
                            <div
                              className={` ${
                                image ? 'hidden' : 'flex'
                              } flex-col items-center justify-center space-y-1 text-center`}
                            >
                              <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                              >
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <div className="flex text-sm text-gray-600">
                                <span className={'text-indigo-600'}>
                                  Upload a Link Preview Image
                                </span>
                              </div>
                              <p className="text-xs text-gray-500">
                                Max size is 2MB
                              </p>
                            </div>
                          </label>
                        </div>

                        {/* <div className={'flex flex-col space-y-1 w-full'}>
                                            <p className={"text-lg font-primary text-gray-900 font-semibold"}>Description</p>
                                            <div className="mt-1 w-full">
                                                <textarea
                                                    placeholder={"This is a card description and goes under the title."}
                                                    value={description}
                                                    onChange={(event) => setDescription(event.target.value)}
                                                    id="bio"
                                                    name="bio"
                                                    rows={4} maxLength={160} className={"p-3 font-medium bg-white border-gray-200 border-2 focus:outline-none focus:ring-0 focus:border-gray-900 transition duration-200 rounded-lg w-full"}
                                                />
                                            </div>
                                        </div> */}
                      </>
                    ) : null}
                  </div>

                  <div className={'w-full bg-gray-100 p-4 px-8'}>
                    <div
                      className={`mb-2 w-full border-l-2 border-red-500 bg-red-500 bg-opacity-10 p-2 ${
                        errorMSG.length > 1 ? 'flex' : 'hidden'
                      } `}
                    >
                      <p className={'w-full text-center text-sm text-red-500'}>
                        {errorMSG}
                      </p>
                    </div>
                    {hasContent ? (
                      <Button click={() => saveLinkCard()} width={'full'}>
                        Save Changes
                      </Button>
                    ) : (
                      <Button
                        click={() => getLinkData()}
                        width={'full'}
                        loading={fetchingData}
                        disabled={fetchingData}
                      >
                        Create Link Card
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="relative z-50 font-primary">
        <Toaster position="bottom-right" reverseOrder={false} />
      </div>
    </>
  );
}
