import { XIcon } from '@heroicons/react/solid';
import EditIcon from '../../Icons/edit';

const backgrounds = [
  { id: 0, bg: 'bg-white', text: 'text-gray-900' },
  {
    id: 1,
    bg: 'bg-gradient-to-b from-gray-200 to-gray-300',
    text: 'text-gray-900',
  },
  {
    id: 2,
    bg: 'bg-gradient-to-b from-gray-900 to-gray-800',
    text: 'text-white',
  },
  {
    id: 3,
    bg: 'bg-gradient-to-b from-blue-500 to-indigo-600',
    text: 'text-white',
  },
  {
    id: 4,
    bg: 'bg-gradient-to-b from-red-400 to-orange-400',
    text: 'text-white',
  },
  {
    id: 5,
    bg: 'bg-gradient-to-b from-yellow-400 to-yellow-500',
    text: 'text-white',
  },
  {
    id: 6,
    bg: 'bg-gradient-to-b from-pink-500 to-indigo-500',
    text: 'text-white',
  },
  {
    id: 7,
    bg: 'bg-gradient-to-b from-green-500 to-green-600',
    text: 'text-white',
  },
];

export default function TextSocialBlogCard({
  removeCard,
  edit,
  editTextCard,
  card,
}) {
  return (
    <a
      href={edit ? null : card.link}
      target={'_blank'}
      rel="noreferrer noopener"
      className={`${backgrounds[card.background].bg} ${
        backgrounds[card.background].text
      }  group relative flex h-full w-full cursor-move items-center justify-center p-6`}
    >
      <div
        className={
          ' flex flex-col items-start justify-center space-y-2 text-left'
        }
      >
        <p className={'font-primary text-2xl font-bold'}>{card.title}</p>

        <p className={'font-medium opacity-90'}>{card.description}</p>
      </div>

      {edit ? (
        <div
          className={`${
            edit ? 'flex' : 'hidden'
          } absolute top-2 right-2 flex-row items-center justify-center space-x-2 text-gray-900`}
        >
          <button
            onClick={() => editTextCard()}
            className={
              'rounded-full border border-gray-200 bg-white p-1.5 opacity-0  ring-gray-200 transition-all duration-200 hover:text-gray-700 hover:ring-4 group-hover:opacity-100'
            }
          >
            <EditIcon className={'h-6 w-6'} />
          </button>

          <button
            onClick={() => removeCard()}
            className={
              'rounded-full border border-gray-200 bg-white p-2.5 opacity-0  ring-red-100 transition-all duration-200 hover:text-red-500 hover:ring-4 group-hover:opacity-100'
            }
          >
            <XIcon className={'h-5 w-5'} />
          </button>
        </div>
      ) : null}
    </a>
  );
}
