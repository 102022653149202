import { LinkIcon, XIcon } from '@heroicons/react/solid';
import { useRouter } from '../../SocialBlogContext';
import EditIcon from '../../Icons/edit';
import Image from 'next/image';
import TwitterBirdIcon from '../../Icons/twitter';

var twitter = require('twitter-text');

const backgrounds = [
  { id: 0, bg: 'bg-white', text: 'text-gray-900' },
  {
    id: 1,
    bg: 'bg-gradient-to-b from-gray-200 to-gray-300',
    text: 'text-gray-900',
  },
  {
    id: 2,
    bg: 'bg-gradient-to-b from-gray-900 to-gray-800',
    text: 'text-white',
  },
  {
    id: 3,
    bg: 'bg-gradient-to-b from-blue-500 to-indigo-600',
    text: 'text-white',
  },
  {
    id: 4,
    bg: 'bg-gradient-to-b from-red-400 to-orange-400',
    text: 'text-white',
  },
  {
    id: 5,
    bg: 'bg-gradient-to-b from-yellow-400 to-yellow-500',
    text: 'text-white',
  },
  {
    id: 6,
    bg: 'bg-gradient-to-b from-pink-500 to-indigo-500',
    text: 'text-white',
  },
  {
    id: 7,
    bg: 'bg-gradient-to-b from-green-500 to-green-600',
    text: 'text-white',
  },
];

const trustedUrls = [
  'pinterest.com',
  'twitter.com',
  'facebook.com',
  'instagram.com',
  'youtube.com',
  'linkedin.com',
  'medium.com',
  'github.com',
  'reddit.com',
  'tumblr.com',
  'flickr.com',
  'dribbble.com',
  'deviantart.com',
  'medium.com',
  'quora.com',
  'slideshare.net',
  'soundcloud.com',
  'vimeo.com',
  'vine.co',
  'spotify.com',
  'twitch.tv',
];

export default function LinkSocialBlogCard({
  removeCard,
  edit,
  editCard,
  card,
}) {
  const router = useRouter();

  const close = (event) => {
    event.stopPropagation();
    removeCard();
  };
  const open = (event) => {
    event.stopPropagation();
    router.push('/');
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const truncate = (str) => {
    var numOfDots = 3;
    var maxLength = 64;

    if (str.length > maxLength + numOfDots) {
      return str.substr(0, 48) + '\u2026';
    } else {
      return str;
    }
  };

  const autoLinkTweet = (tweet) => {
    var tweetElement = twitter.default.autoLink(tweet);

    var regexpAt = /@/g;
    var matchAt = regexpAt.exec(tweet);

    while (matchAt != null) {
      tweetElement = tweetElement.replaceAll(
        matchAt[0],
        `<span class='text-blue-400'>${matchAt[0]}</span>`,
      );
      matchAt = regexpAt.exec(tweet);
    }

    return tweetElement;
  };

  return (
    <>
      {card ? (
        <a
          href={edit ? null : card.link}
          target={'_blank'}
          rel="noreferrer noopener"
          className={` ${card.tag === 'Book' ? 'p-0' : null} ${
            edit
              ? 'cursor-move '
              : 'transform cursor-pointer duration-200 hover:scale-105 active:scale-100'
          } group relative flex h-full w-full flex-col items-center  justify-between overflow-hidden rounded-2xl bg-white antialiased`}
        >
          {card.tag === 'Book' || card.tag === 'Twitter User' ? (
            card.image.includes('firebasestorage') ? (
              <Image
                layout="fill"
                className={'absolute z-0 h-full w-full blur-2xl filter '}
                src={card.image}
              />
            ) : card.tag === 'Tweet' ? (
              <Image
                layout="fill"
                className={'absolute z-0 h-full w-full'}
                src={'/tweet-bg.png'}
              />
            ) : (
              <img
                className={'absolute z-0 h-full w-full blur-2xl filter '}
                src={card.image}
              />
            )
          ) : null}

          {card.tag === 'Book' ? (
            <div className="z-10 flex h-full w-full items-center justify-center p-24">
              <div className="relative w-full rotate-12 transform overflow-hidden rounded-md shadow-lg duration-500 group-hover:scale-110  ">
                <div className="absolute left-[4px] z-10 h-full w-[2px] bg-black bg-opacity-20 blur-[2px] filter " />
                <img
                  className={'h-auto w-full rounded-md object-cover'}
                  src={card.image}
                />
              </div>
            </div>
          ) : card.tag === 'Tweet' ? (
            <div className=" relative z-10 flex h-full w-full flex-col items-start justify-start overflow-y-auto rounded-2xl bg-blue-50 p-6">
              <TwitterBirdIcon
                className={'absolute top-4 right-4 h-6 w-6 text-blue-400'}
              />
              <div className="mb-4 flex w-full flex-row items-center  justify-start space-x-2">
                <img className="h-10 w-10 rounded-full" src={card.image} />
                <div className="flex flex-col items-start justify-center space-y-0">
                  <p
                    className={
                      'truncate font-sans text-sm font-semibold text-gray-900'
                    }
                  >
                    {card.description.name}
                  </p>
                  <p className="font-sans text-xs text-gray-500">
                    @{card.description.username}
                  </p>
                </div>
              </div>

              <div className="  ">
                <div
                  dangerouslySetInnerHTML={{
                    __html: autoLinkTweet(card.title),
                  }}
                  className={'tweet-text'}
                ></div>
              </div>
            </div>
          ) : card.tag === 'Twitter User' ? (
            <div className="z-10 flex h-full w-full items-center justify-center">
              <img
                className={
                  'h-20 w-20 transform rounded-full object-cover shadow-lg duration-500 group-hover:scale-110'
                }
                src={card.image}
              />
            </div>
          ) : card?.image && card?.image?.includes('firebasestorage') ? (
            <Image
              layout="fill"
              className={'z-10 h-full w-full object-cover'}
              src={card.image}
            />
          ) : (
            <img
              className={'z-10 h-full w-full object-cover'}
              src={card.image}
            />
          )}

          {card.tag === 'Tweet' ? null : (
            <div
              className={
                'absolute z-20 flex h-full w-full items-end justify-start rounded-3xl bg-gradient-to-b from-transparent to-[#00000075] p-4'
              }
            >
              {card.tag === 'Twitter User' ? null : (
                <div
                  className={`${
                    card.tag ? 'flex' : 'hidden'
                  } absolute top-2 left-2 z-20 rounded-full bg-black bg-opacity-25 py-2 px-3 text-sm font-medium text-white backdrop-blur-xl backdrop-filter`}
                >
                  <p>{capitalizeFirstLetter(card.tag)}</p>
                </div>
              )}

              <div
                className={
                  ' flex flex-col items-start justify-center space-y-1 text-left text-white'
                }
              >
                <p className={'font-primary text-lg font-bold '}>
                  {truncate(card.title)}
                </p>

                <div className="flex flex-row items-center justify-start space-x-2">
                  <LinkIcon className="h-3 w-3 " />
                  <p
                    className={
                      'w-32 truncate overflow-ellipsis font-sans text-sm'
                    }
                  >
                    {
                      card.link
                        .replace('https://', '')
                        .replace('http://', '')
                        .replace('www.', '')
                        .replace(/\/$/, '')
                        .split('/')[0]
                    }
                  </p>
                </div>
              </div>
            </div>
          )}

          {edit ? (
            <div
              className={`${
                edit ? 'flex' : 'hidden'
              } absolute top-2 right-2 z-20 flex flex-row items-center justify-center space-x-2`}
            >
              {card.tag === 'Twitter User' || card.tag === 'Tweet' ? null : (
                <button
                  onMouseDown={(event) => editCard()}
                  onTouchStart={(event) => editCard()}
                  className={
                    ' flex rounded-full bg-white p-2 opacity-0 ring-gray-200 transition-all duration-200 hover:ring-4 group-hover:opacity-100'
                  }
                >
                  <EditIcon className={'h-4 w-4'} />
                </button>
              )}

              <button
                onMouseDown={(event) => close(event)}
                onTouchStart={(event) => close(event)}
                className={
                  ' rounded-full bg-white p-2 text-red-500 opacity-0  ring-red-100 transition-all duration-200 hover:ring-4 group-hover:opacity-100'
                }
              >
                <XIcon className={'h-4 w-4'} />
              </button>
            </div>
          ) : null}
        </a>
      ) : !edit ? null : (
        <div
          style={{ boxShadow: '0px 4px 24px 0px #0000000D' }}
          className={` ${
            edit
              ? 'cursor-move'
              : 'transform cursor-pointer duration-200 hover:scale-105 active:scale-100'
          } group relative flex h-full w-full items-center justify-center overflow-hidden  rounded-3xl border border-dashed border-gray-200 bg-gray-100`}
        >
          <div
            className={`${
              edit ? 'flex' : 'hidden'
            } absolute top-2 right-2 flex-row items-center justify-center space-x-2 text-gray-900`}
          >
            <div
              className={`${
                edit ? 'flex' : 'hidden'
              } absolute top-2 right-2 z-20 flex flex-row items-center justify-center space-x-2`}
            >
              <button
                onClick={() => editCard()}
                className={
                  ' flex rounded-full bg-white p-2 opacity-0 ring-gray-200 transition-all duration-200 hover:ring-4 group-hover:opacity-100'
                }
              >
                <EditIcon className={'h-4 w-4'} />
              </button>

              <button
                onClick={(event) => close(event)}
                className={
                  ' rounded-full bg-white p-2 text-red-500 opacity-0  ring-red-100 transition-all duration-200 hover:ring-4 group-hover:opacity-100'
                }
              >
                <XIcon className={'h-4 w-4'} />
              </button>
            </div>
          </div>

          <button
            onClick={(event) => editCard()}
            className={
              'flex transform cursor-pointer rounded-full bg-gray-800 py-2 px-3 text-sm font-medium text-white backdrop-blur-xl  backdrop-filter duration-200 hover:scale-105 active:scale-100'
            }
          >
            <p>Add Link</p>
          </button>
        </div>
      )}
    </>
  );
}
