import { ArrowUpIcon } from '@heroicons/react/solid';
import { useRouter } from '../../SocialBlogContext';
import { useState } from 'react';
import EssayCard from '../../Cards/essayCard';

export default function FeaturedEssaysCard({ blogMeta, typeshares }) {
  const [filter, setFilter] = useState('imageEssay');
  const router = useRouter();

  const formatTS = (ts) => {
    if (ts) {
      var format = {
        id: ts.key,
        val: ts.val(),
      };
      return format;
    } else {
      return null;
    }
  };

  return (
    <div
      key="d"
      className={' flex w-full flex-col justify-between space-y-4 p-6'}
    >
      <div className={'flex w-full flex-row items-center justify-between'}>
        <div className={' mr-auto flex w-max flex-row space-x-6 font-sans'}>
          <button
            onClick={() => setFilter('imageEssay')}
            className={` ${
              filter === 'imageEssay'
                ? '  border-gray-900 text-gray-900'
                : 'border-transparent text-gray-600'
            } border-b-2 pb-2 font-primary text-base font-semibold antialiased`}
          >
            Essays
          </button>
        </div>
        <button className={'rounded-full bg-gray-700 p-2'}>
          <ArrowUpIcon
            className={'h-4 w-4 rotate-45 transform text-gray-300'}
          />
        </button>
      </div>

      {filter === 'imageEssay' ? (
        typeshares.length === 0 ? (
          <div
            className={
              'mb-24 flex w-full items-center justify-center rounded-xl border border-dashed border-gray-200  bg-gray-50 py-24 px-8 text-center text-base text-gray-700 sm:text-lg'
            }
          >
            <p>{blogMeta.displayName} hasn't published any essays yet</p>
          </div>
        ) : (
          <div
            className={
              'mb-32 grid w-full grid-flow-row grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-4'
            }
          >
            {typeshares.map((typeshare, i) => (
              <EssayCard
                key={typeshare.id}
                typeshare={formatTS(typeshare)}
                click={() =>
                  router.push({
                    pathname: '/[slug]/posts/[post]',
                    query: { slug: blogMeta.slug, post: typeshare.key },
                  })
                }
              />
            ))}
          </div>
        )
      ) : null}
    </div>
  );
}
