/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, TemplateIcon } from '@heroicons/react/outline';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { PhotographIcon } from '@heroicons/react/outline';
import SocialBlogIcon from '../Icons/blog';
import TemplatesIcon from '../Icons/templates';
import { useRouter } from 'next/router';
import Button from '../Button';

export default function UpgradeModal({
  isOpen,
  setIsOpen,
  msg = 'To use this feature, you need to join Typeshare Pro',
}) {
  const router = useRouter();
  const cancelButtonRef = useRef();

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        initialFocus={cancelButtonRef}
        className="fixed inset-0 z-50"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div className="flex min-h-screen items-center justify-center pt-8 text-center antialiased sm:block sm:p-0 sm:px-4 sm:pt-4 sm:pb-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm backdrop-filter transition-all" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-24 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-24 sm:scale-95"
          >
            <div className=" relative inline-block transform rounded-2xl bg-white p-6 text-center align-bottom antialiased shadow-xl transition-all sm:w-full sm:max-w-md sm:align-middle">
              <div className={'flex flex-col items-center justify-center'}>
                <Dialog.Title
                  as="h3"
                  className="font-primary text-2xl font-bold text-gray-900"
                >
                  This is a Pro feature
                </Dialog.Title>
                <p className={'mt-2 mb-8 text-gray-600'}>{msg}</p>

                <div className={'flex w-full flex-col space-y-3'}>
                  <div className="flex w-full flex-col space-y-3 xs:flex-row xs:space-y-0 xs:space-x-3">
                    <Button
                      width={'full'}
                      variant={'primary'}
                      ref={cancelButtonRef}
                      click={() => router.push('/pro')}
                    >
                      Start a Free 30 Day Trial
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
