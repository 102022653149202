import { ArrowUpIcon, XIcon } from '@heroicons/react/solid';
import EditIcon from '../../Icons/edit';

export default function ProfileCard({ blogMeta, edit, removeCard }) {
  return (
    <div
      key="a"
      className={` ${
        edit ? 'cursor-move' : 'cursor-default'
      } group relative flex h-full w-full flex-col items-center justify-center space-y-4 p-6 text-gray-900`}
    >
      <div className={'flex flex-col items-start justify-start space-y-4'}>
        <div className={'flex flex-row items-center justify-center space-x-4'}>
          <img
            className={
              'h-24 w-24 rounded-full border-4 border-white bg-gradient-to-br from-indigo-400 via-pink-500 to-orange-600'
            }
            alt={blogMeta.displayName}
            src={blogMeta.image}
          />

          <div className={'flex flex-col'}>
            <h1
              className={
                ' whitespace-wrap mr-auto overflow-hidden overflow-ellipsis font-primary text-4xl font-bold tracking-tight text-gray-900 antialiased'
              }
            >
              {blogMeta.displayName}
            </h1>
            <div className={'flex flex-row space-x-2 antialiased'}>
              <p
                className={
                  'bg-gradient-to-br from-[#C242FF] to-[#FF5D40] bg-clip-text font-primary text-lg font-semibold text-transparent'
                }
              >
                @{blogMeta.slug}
              </p>
            </div>
          </div>
        </div>

        <div className={'flex flex-col'}>
          {/*   <div className={"flex flex-row space-x-2"}>

                    <p className={"font-sans  antialiased text-gray-600"}>Creator of Typeshare.co • Canada</p>

                    </div> */}

          <p className={'font-sans  text-gray-600 antialiased'}>
            {blogMeta.bio}
          </p>

          {/*  {edit ?

                        <div className={`${edit ? "flex" : "hidden"} absolute top-2 right-2 flex-row items-center justify-center space-x-2`}>
                            <button className={`opacity-0 group-hover:opacity-100 transition-all duration-200 border-gray-200 border  bg-white rounded-full p-1.5 hover:ring-4 ring-gray-200 hover:text-gray-700`}>
                                <EditIcon className={"w-6 h-6"} />
                            </button>

                            <button onClick={() => removeCard()} className={`opacity-0 group-hover:opacity-100 transition-all duration-200 border-gray-200 border  bg-white rounded-full p-2.5 hover:ring-4 ring-red-100 hover:text-red-500`}>
                                <XIcon className={"w-5 h-5"} />
                            </button>
                        
                        </div>

                        : null



                    } */}

          {/* <div className={"mt-6 flex flex-row space-x-2"}>


                        <div className={"rounded-full flex items-center bg-gradient-to-br from-blue-100 space-x-2 to-indigo-100 p-2 pr-4 justify-center hover:scale-110 transition transform duration-200 "}>
                            <img src={"diamond4.png"} className={"w-8"} />
                            <p className={"font-primary font-bold text-sm text-blue-600"}>Top writer</p>
                        </div>

                        <div className={"rounded-full flex items-center bg-gradient-to-br from-yellow-100 space-x-2 to-orange-100 p-2 pr-4 justify-center hover:scale-110 transition transform duration-200 "}>
                            <p className={"text-xl ml-1"}>✍️</p>
                            <p className={"font-primary font-bold text-sm text-yellow-600"}>500 Words</p>
                        </div>

                        <div className={"rounded-full flex items-center bg-gradient-to-br from-orange-100 space-x-2 to-red-100 w-12 h-12 justify-center hover:scale-110 transition transform duration-200 "}>
                            <p className={"font-primary font-bold text-sm text-red-500"}>8🔥</p>
                        </div>

                    </div> */}
        </div>
      </div>
    </div>
  );
}
