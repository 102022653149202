import ThemeSwitcher from 'react-css-vars';
import * as themes from '../Themes';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { useEffect, useState } from 'react';
import EssayLoadingState from '../LoadingState/EssayLoadingState';
import { EyeOffIcon, SwitchHorizontalIcon } from '@heroicons/react/solid';
import {
  ChatIcon,
  EyeIcon,
  HeartIcon,
  ReplyIcon,
} from '@heroicons/react/outline';

var twitter = require('twitter-text');

const getDate = (date) => {
  const options = { month: 'short', day: 'numeric' };
  const newDate = new Date(date).toLocaleDateString(undefined, options);
  return newDate;
};

export default function ThreadCard({
  threadID = null,
  imported,
  userMatch = null,
  showStats = false,
  statEmphasis = null,
  likes,
  views,
  shares,
  replies,
  creatorSlug = null,
  creatorID = null,
  creatorMeta = null,
  passedThread = null,
  click = null,
  edit = false,
  allowHide = false,
  hideThread = null,
  maxH = false,
  border,
  borderType = 2,
}) {
  const [loading, setLoading] = useState(true);
  const [slug, setSlug] = useState(true);
  const [uid, setUID] = useState(true);
  const [thread, setThread] = useState([]);

  useEffect(() => {
    if (passedThread === null) {
      creatorSlug.length > 1 && creatorID.length > 5 ? fetchThread() : null;
    } else {
      setThread(passedThread);
      setLoading(false);
    }
  }, [threadID, creatorSlug, creatorID, passedThread]);

  const fetchThread = () => {
    if (userMatch) {
      firebase
        .database()
        .ref('gallery/' + creatorSlug + '/' + creatorID)
        .child('threads')
        .child(threadID)
        .once('value')
        .then((snap) => {
          setThread(snap.val());

          setLoading(false);
        });
    }
  };

  const autoLinkTweet = (tweet) => {
    var tweetElement = twitter.default.autoLink(tweet);

    var regexpAt = /@/g;
    var matchAt = regexpAt.exec(tweet);

    while (matchAt != null) {
      tweetElement = tweetElement.replaceAll(
        matchAt[0],
        `<span class='text-blue-400'>${matchAt[0]}</span>`,
      );
      matchAt = regexpAt.exec(tweet);
    }

    return tweetElement;
  };

  return loading ? (
    <EssayLoadingState />
  ) : (
    <div
      style={{ boxShadow: '0px 0px 34px -2px rgba(0, 0, 0, 0.025)' }}
      className={`flex flex-col ${
        maxH ? null : 'h-full'
      } relative w-full overflow-hidden rounded-2xl border-gray-100  from-white via-white to-white  font-thread  ${
        userMatch ? null : ' hover:scale-105 active:scale-100'
      }  group transform transition duration-200 ${
        border
          ? borderType === 2
            ? 'border-2 border-gray-100'
            : 'border border-gray-100'
          : null
      }`}
    >
      <button
        onClick={click}
        className={`${
          edit ? 'cursor-move' : 'cursor-pointer'
        } relative flex h-full w-full flex-col items-start justify-start bg-gradient-to-b p-6 pb-16 text-left text-gray-800`}
      >
        <div
          className={`${
            allowHide ? 'flex' : 'hidden'
          } absolute top-2 right-2 z-10 flex flex-row items-center justify-center space-x-2`}
        >
          <button
            onClick={(event) => hideThread(event)}
            className={
              ' rounded-full bg-gray-100 p-2 text-red-500 opacity-0  ring-red-100 transition-all duration-200 hover:ring-4 group-hover:opacity-100'
            }
          >
            <EyeOffIcon className={'h-4 w-4'} />
          </button>
        </div>

        <div
          className={`${
            imported ? 'flex' : 'hidden'
          } absolute top-4 right-4 z-10 flex flex-row items-center justify-center space-x-2`}
        >
          <button
            onClick={(event) => hideThread(event)}
            className={
              'rounded-full text-gray-500 transition-all  duration-200'
            }
          >
            <SwitchHorizontalIcon className="h-4 w-4" />
          </button>
        </div>

        <div
          className={'mb-4 flex flex-row items-center justify-center space-x-4'}
        >
          <svg
            className={'h-6 w-6 fill-current text-blue-400'}
            viewBox="0 0 21 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M21 2.77246C20.2271 3.10039 19.3978 3.32279 18.5264 3.42208C19.4161 2.91193 20.0972 2.10275 20.42 1.14152C19.5854 1.61395 18.6642 1.957 17.6826 2.14297C16.8966 1.34007 15.7785 0.839966 14.5384 0.839966C12.1593 0.839966 10.2303 2.68703 10.2303 4.96382C10.2303 5.28673 10.2683 5.60211 10.3418 5.9037C6.76201 5.73153 3.58768 4.08929 1.46314 1.59385C1.09176 2.20201 0.880492 2.91065 0.880492 3.66709C0.880492 5.09827 1.64161 6.36105 2.7964 7.09985C2.0904 7.07725 1.42639 6.89128 0.84508 6.58217V6.63369C0.84508 8.63153 2.33056 10.2989 4.30024 10.6784C3.93936 10.7714 3.55882 10.8229 3.16514 10.8229C2.88696 10.8229 2.61794 10.7965 2.35417 10.7462C2.90267 12.386 4.49315 13.5784 6.37756 13.6111C4.90388 14.7168 3.04573 15.374 1.02749 15.374C0.679756 15.374 0.337236 15.3539 0 15.3174C1.90671 16.4898 4.17036 17.1733 6.60327 17.1733C14.528 17.1733 18.8597 10.8882 18.8597 5.43754L18.8453 4.90353C19.6917 4.32549 20.4239 3.59924 21 2.77246Z" />
          </svg>
          {thread.tweetArray.length > 1 ? (
            <p
              className={
                'items-center justify-center rounded-md bg-gray-200 p-1 px-1.5 font-sans text-sm font-medium'
              }
            >
              {thread.tweetArray.length}🧵
            </p>
          ) : null}
        </div>

        <div
          dangerouslySetInnerHTML={{
            __html: autoLinkTweet(thread.tweetArray[0]),
          }}
          className={'tweet-text'}
        ></div>

        <div
          style={{
            background:
              'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
          }}
          className={
            ' group absolute left-0 bottom-0 mt-4 flex w-full flex-row items-center justify-start space-x-2 rounded-b-2xl p-6 pt-16 text-gray-700'
          }
        >
          <img
            className={
              'h-8 w-8 transform rounded-full bg-gradient-to-br from-indigo-400 via-pink-500 to-orange-600 transition-all duration-200 group-active:scale-95'
            }
            src={creatorMeta.image}
          />
          <p className={'font-sans text-sm font-medium'}>
            {creatorMeta.displayName}
          </p>
        </div>
      </button>

      {showStats ? (
        <div className="flex w-full flex-row items-center justify-start space-x-1 bg-gray-50 p-3 font-sans text-sm font-medium tracking-tight text-gray-500">
          {/* <div className='flex flex-row space-x-1 items-center justify-center'>
                        <EyeIcon className='w-4 h-4' />
                        <p>{views}</p>
                    </div> */}

          <div
            className={`flex flex-row items-center justify-center space-x-1 rounded-full px-2 py-1 ${
              statEmphasis === 'likes'
                ? 'bg-red-500 bg-opacity-10 text-red-500'
                : null
            }`}
          >
            <HeartIcon className="h-4 w-4" />
            <p>{likes}</p>
          </div>

          <div
            className={`flex flex-row items-center justify-center space-x-1 rounded-full px-2 py-1 ${
              statEmphasis === 'shares'
                ? 'bg-green-500 bg-opacity-10 text-green-500'
                : null
            }`}
          >
            <ReplyIcon className="h-4 w-4" />
            <p>{shares}</p>
          </div>

          <div
            className={`flex flex-row items-center justify-center space-x-1 rounded-full px-2 py-1 ${
              statEmphasis === 'replies'
                ? 'bg-blue-500 bg-opacity-10 text-blue-500'
                : null
            }`}
          >
            <ChatIcon className="h-4 w-4" />
            <p>{replies}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
}
