import {
  ArrowDownIcon,
  DesktopComputerIcon,
  DeviceMobileIcon,
  DeviceTabletIcon,
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import { Fragment, useEffect, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { NextSeo } from 'next-seo';
import EssayCard from '../../components/Cards/essayCard';
import TwitterBirdIcon from '../../components/Icons/twitter';
import VerifiedIcon from '../../components/Icons/verified';
import EditProfile from '../../components/NewModals/edit-profile';
import { Responsive } from 'react-grid-layout';
import { withSize } from 'react-sizeme';
import Image from 'next/image';
import { ArrowLeftIcon, LockClosedIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';
import { SizeMe } from 'react-sizeme';
import Button from '../../components/Button';
import EmptyStateSection from '../../components/EmptyState';
import EssayIconDetailed from '../../components/Icons/essay-icon-detailed';
import EssayLoadingState from '../../components/LoadingState/EssayLoadingState';
import PostTopBar from '../../components/NavContainer/PostTopBar';
import NavItem from '../../components/NavContainerSidebar/nav-item';
import CollectionSelectorModal from '../../components/NewModals/collection-selector';
import EssaySelectorModal from '../../components/NewModals/essay-selector';
import LinkCardEditor from '../../components/NewModals/SocialBlogCards/link-card-editor';
import TextCardEditor from '../../components/NewModals/text-card-editor';
import UpgradeModal from '../../components/NewModals/upgrade';
import CollectionRouterCard from '../../components/SocialBlog/Cards/collection-router';
import CollectionPreviewRouterCard from '../../components/SocialBlog/Cards/collection-router-preview';
import ContentCard from '../../components/SocialBlog/Cards/content';
import EssaySocialBlogCard from '../../components/SocialBlog/Cards/essay';
import EssayCollectionCard from '../../components/SocialBlog/Cards/essay-collection';
import FeaturedEssaysCard from '../../components/SocialBlog/Cards/featured-essays';
import LinkSocialBlogCard from '../../components/SocialBlog/Cards/link';
import ProfileCard from '../../components/SocialBlog/Cards/profile';
import QuoteSocialBlogCard from '../../components/SocialBlog/Cards/quote';
import TextSocialBlogCard from '../../components/SocialBlog/Cards/text';
import ThreadSocialBlogCard from '../../components/SocialBlog/Cards/thread';
import ThreadCollectionCard from '../../components/SocialBlog/Cards/thread-collection';
import TwitterSocialBlogCard from '../../components/SocialBlog/Cards/twitter';
import { sortPosts } from '../../utils/library';
import { resolveSlug } from '../../utils/slugs';
import {
  SocialBlogContextProvider,
  useRouter,
} from '../../components/SocialBlogContext';

const withSizeHOC = withSize();
const ResponsiveReactGridLayout = withSizeHOC(Responsive);

const proPlans = [
  /*     "Hobby", */
  'Expert',
  'Pro',
  'Expert Lifetime',
  'Premier',
];

export const getStaticProps = async ({ params: { slug } }) => {
  try {
    const firebaseConfig = {
      apiKey: 'AIzaSyA64Cm0bTMY9Rinvha_z3ENAUhkJssFCO8',
      authDomain: 'auth.typeshare.co',
      projectId: 'typeshare-2027d',
      storageBucket: 'typeshare-2027d.appspot.com',
      databaseURL: 'https://typeshare-2027d-default-rtdb.firebaseio.com/',
      messagingSenderId: '305996917305',
      appId: '1:305996917305:web:704a11e8f876840e87bbe8',
      measurementId: 'G-LX68JP4ZZK',
    };

    //TEST APP

    const firebaseConfigStaging = {
      apiKey: 'AIzaSyD5pDmQa1lIAYiW1qd7Swez2gaXG-ggWtc',
      authDomain: 'test-88a5a.firebaseapp.com',
      projectId: 'test-88a5a',
      storageBucket: 'test-88a5a.appspot.com',
      messagingSenderId: '952460692987',
      appId: '1:952460692987:web:0b0eb7f65c0e3f6767cf5e',
    };

    if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
      if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
      }
    } else {
      if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfigStaging);
      }
    }

    //fetch any data that is needed for this page.

    //Get userID from slug

    const {
      slug: slugLowercase,
      isCustomDomain,
      hostname,
    } = await resolveSlug(firebase.database(), slug);

    var galleryUserID = '';
    const galleryUserRef = await firebase
      .database()
      .ref('gallery')
      .child(slugLowercase)
      .once('value');
    galleryUserRef.forEach((doc) => {
      galleryUserID = doc.key;
    });

    var blogMeta = [];
    const blogMetaRef = await firebase
      .database()
      .ref('gallery')
      .child(slugLowercase)
      .child(galleryUserID)
      .child('meta')
      .once('value');
    if (blogMetaRef.hasChild('displayName')) {
      blogMeta = blogMetaRef.val();
    } else {
      const blogMetaRef2 = await firebase
        .database()
        .ref('gallery')
        .child(slugLowercase)
        .child(galleryUserID)
        .once('value');
      blogMeta = {
        twitterURL: blogMetaRef2.val().twitterURL,
        displayName: blogMetaRef2.val().displayName,
        image: blogMetaRef2.val().image,
        bio: blogMetaRef2.val().bio,
        slug: blogMetaRef2.val().slug,
        coverImage: '',
        verified: false,
      };
    }

    const cardsRef = await firebase
      .database()
      .ref('gallery')
      .child(slugLowercase)
      .child(galleryUserID)
      .child('cards')
      .once('value');
    const cards = cardsRef.val();

    var gallerySlug = slugLowercase;

    return {
      props: {
        slug,
        isCustomDomain,
        hostname,
        blogMetaInitial: blogMeta,
        galleryUserID,
        gallerySlug,
        cards,
        exists: true,
        STRIPE_PK: process.env.STRIPE_PK,
      },
      revalidate: 1,
    };
  } catch (error) {
    return {
      props: {
        exists: false,
        STRIPE_PK: process.env.STRIPE_PK,
      },
      revalidate: 1,
    };
  }
};

export async function getStaticPaths() {
  // Get the paths we want to pre-render based on posts
  /*  const firebaseConfig = {
         apiKey: "AIzaSyA64Cm0bTMY9Rinvha_z3ENAUhkJssFCO8",
         authDomain: "auth.typeshare.co",
         projectId: "typeshare-2027d",
         storageBucket: "typeshare-2027d.appspot.com",
         databaseURL: "https://typeshare-2027d-default-rtdb.firebaseio.com/",
         messagingSenderId: "305996917305",
         appId: "1:305996917305:web:704a11e8f876840e87bbe8"
     };
 
     if (firebase.apps.length === 0) {
         firebase.initializeApp(firebaseConfig);
     } */

  var paths = [];
  /* const entries = await firebase.database().ref('paths/blogs').orderByKey().once('value')
    entries.forEach((doc) => {
        var slug = doc.val().slug
        paths.push({
            params: { slug }
        })

    }) */

  return {
    paths,
    fallback: 'blocking',
  };
}

function Gallery({
  blogMetaInitial,
  galleryUserID,
  gallerySlug,
  cards,
  exists = true,
  productName,
  STRIPE_PK,
}) {
  const router = useRouter();

  const [blogMeta, setBlogMeta] = useState(blogMetaInitial);

  const optimizedImage = exists
    ? blogMeta !== undefined || blogMeta !== null
      ? blogMeta.image?.includes('twimg')
        ? blogMeta.image?.replace('_normal', '')
        : blogMeta.image?.includes('googleusercontent')
        ? blogMeta.image?.replace('=s96-c', '')
        : blogMeta.image
      : null
    : null;

  const ogImage = exists
    ? blogMeta !== undefined || blogMeta !== null
      ? `https://typeshare-opengraphs-three.vercel.app/${encodeURIComponent(
          blogMeta.displayName,
        )}.png?theme=light&bio=${encodeURIComponent(
          blogMeta.bio,
        )}&images=${encodeURIComponent(
          optimizedImage,
        )}&collectionIMG=${encodeURIComponent(
          blogMeta?.coverImage
            ? blogMeta?.coverImage
            : 'https://typeshare.co/default-header.png',
        )}`
      : null
    : null;

  const [isOnboarding, setOnboarding] = useState(false);
  const [loggedInUserID, setLoggedInUserID] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [activeWidth, setActiveWidth] = useState('lg');

  const [layoutsLoading, setLayoutsLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);

  const [cardList, setCardList] = useState([]);
  const [proModalOpen, setProModalOpen] = useState(false);
  const [proModalMSG, setProModalMSG] = useState(
    'To use this feature, you need to join Typeshare Pro',
  );

  const [isEssaySelectorOpen, setEssaySelectorOpen] = useState(false);
  const [essaySelectorID, setEssaySelectorID] = useState(null);

  const [isCollecionSelectorOpen, setCollectionSelectorOpen] = useState(false);
  const [collectionSelectorID, setCollectionSelectorID] = useState(null);

  const [isTextCardEditorOpen, setTextCardEditorOpen] = useState(false);
  const [selectedTextCardID, setSelectedTextCardID] = useState(null);

  const [filter, setFilter] = useState('imageEssay');

  const [threads, setThreads] = useState([]);
  const [typeshares, setTypeshares] = useState([]);

  const [isLinkCardEditorOpen, setLinkCardEditorOpen] = useState(false);
  const [selectedLinkCardID, setSelectedLinkCardID] = useState(null);

  const [isThreadSelectorOpen, setThreadSelectorOpen] = useState(false);
  const [threadSelectorID, setThreadSelectorID] = useState(null);

  const [layouts, setLayouts] = useState({});
  const [theme, setTheme] = useState({
    colorID: 1,
    customTheme: {
      default: false,
    },
    fontID: 2,
  });

  const [copied, setCopied] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    loggedInUserID === galleryUserID ? setEdit(true) : setEdit(false);
  }, [loggedInUserID, galleryUserID]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (userInfo) {
      if (userInfo) {
        setLoggedInUserID(userInfo.uid);
        setLoggedInUser(userInfo);
        if (userInfo.uid === galleryUserID) {
          //edit mode
          fetchData();
        }
        setOnboarding(false);
      } else {
        setOnboarding(true);
        setLoggedInUserID(null);
      }
    });
  }, []);

  useEffect(() => {
    fetchPosts(galleryUserID, gallerySlug);
  }, [galleryUserID, gallerySlug]);

  useEffect(() => {
    galleryUserID && gallerySlug ? getPrivateLayouts() : null;
    galleryUserID && gallerySlug ? getTheme() : null;
    galleryUserID && gallerySlug ? getPrivateCards() : null;
    galleryUserID && gallerySlug
      ? updatePostList(galleryUserID, gallerySlug)
      : null;
    galleryUserID && gallerySlug
      ? getThreads(galleryUserID, gallerySlug)
      : null;
  }, [galleryUserID, gallerySlug]);

  function getPrivateLayouts() {
    const ref = firebase
      .database()
      .ref('gallery/' + blogMeta.slug + '/' + galleryUserID + '/blog/');
    ref.once('value').then(function (snapshot) {
      if (snapshot.exists()) {
        setLayouts(snapshot.val().layouts);
      }
    });
  }

  function updatePostList(uid, gallerySlug) {
    if (gallerySlug) {
      var ref = firebase
        .database()
        .ref(
          'gallery/' +
            gallerySlug.toLocaleLowerCase() +
            '/' +
            uid +
            '/typeshares/',
        )
        .orderByChild('publishedAt/unix');
      ref.once('value', (querySnapshot) => {
        var typesharesTemp = [];
        querySnapshot.forEach((doc) => {
          //Check if content exists - this should stop an timestamp/visible only Typeshare from erroring the page

          if (doc.hasChild('content')) {
            if (doc.hasChild('publishedAt')) {
              const publishedTime = doc.val().publishedAt.unix;
              const currentTime = Date.now();

              if (publishedTime < currentTime) {
                doc.val().visible ? typesharesTemp.push(doc) : null;
              }
            } else {
              //for early essays without published timestamps
              doc.val().visible ? typesharesTemp.push(doc) : null;
            }
          }
        });
        setTypeshares(typesharesTemp.reverse());
      });
    }
  }

  function getThreads(uid, gallerySlug) {
    if (gallerySlug) {
      var ref = firebase
        .database()
        .ref(
          'gallery/' +
            gallerySlug.toLocaleLowerCase() +
            '/' +
            uid +
            '/threads/',
        )
        .orderByChild('publishedAt/unix');
      ref.once('value', (querySnapshot) => {
        if (querySnapshot.exists()) {
          var threadsTemp = [];
          querySnapshot.forEach((doc) => {
            var hasTimestamp = doc.hasChild('publishedAt');

            if (hasTimestamp) {
              const publishedTime = doc.val().publishedAt.unix;
              const currentTime = Date.now();

              if (publishedTime < currentTime) {
                threadsTemp.push(doc);
              }
            }
          });
          setThreads(threadsTemp.reverse());
        }
      });
    }
  }

  const [posts, setPosts] = useState([]);
  const [next, setNext] = useState(null);

  const selectedEssay = (key, type) => {
    if (type === 'thread') {
      firebase
        .database()
        .ref('gallery/' + blogMeta.slug + '/' + galleryUserID + '/blog/cards/')
        .child(essaySelectorID)
        .update({
          content: {
            id: key,
          },
          type: 'thread',
        })
        .then(() => {
          getPrivateCards();
          setEssaySelectorOpen(false);
        });
    } else {
      firebase
        .database()
        .ref('gallery/' + blogMeta.slug + '/' + galleryUserID + '/blog/cards/')
        .child(essaySelectorID)
        .child('content')
        .update({
          essayID: key,
        })
        .then(() => {
          getPrivateCards();
          setEssaySelectorOpen(false);
        });
    }
  };

  const selectedCollection = (key) => {
    firebase
      .database()
      .ref('gallery/' + blogMeta.slug + '/' + galleryUserID + '/blog/cards/')
      .child(collectionSelectorID)
      .child('content')
      .update({
        id: key,
      })
      .then(() => {
        getPrivateCards();
        setCollectionSelectorOpen(false);
      });
  };

  const openThreadSelector = (id) => {
    setThreadSelectorID(id);
    setThreadSelectorOpen(true);
  };

  async function fetchPosts(uid, slug) {
    // Send token to your backend via HTTPS
    const data = await sortPosts(uid, slug, next);
    setPosts(data.posts);
    setNext(data.next);
  }

  const refreshData = (val) => {
    fetchData();
  };

  async function fetchData() {
    var blogMetaTemp = [];
    const blogMetaRef = await firebase
      .database()
      .ref('gallery')
      .child(gallerySlug)
      .child(galleryUserID)
      .child('meta')
      .once('value');
    if (blogMetaRef.hasChild('displayName')) {
      blogMetaTemp = blogMetaRef.val();
    } else {
      const blogMetaRef2 = await firebase
        .database()
        .ref('gallery')
        .child(gallerySlug)
        .child(galleryUserID)
        .once('value');
      blogMetaTemp = {
        twitterURL: blogMetaRef2.val().twitterURL,
        displayName: blogMetaRef2.val().displayName,
        image: blogMetaRef2.val().image,
        bio: blogMetaRef2.val().bio,
        slug: blogMetaRef2.val().slug,
        coverImage: '',
        verified: false,
      };
    }
    setBlogMeta(blogMetaTemp);
    setProfileModalOpen(false);
  }

  const successToast = (text) =>
    toast.success(text, {
      id: text,
      iconTheme: {
        primary: '#3B82F6',
        secondary: '#fff',
      },
      /* icon: '🪄', */
      style: {
        borderRadius: '16px',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 10px 20px 0px',
        fontWeight: '600',
        padding: '12px',
        fontSize: '18px',
        paddingRight: '8px',
        background: '#fff',
        color: '#181818',
      },
    });

  const problemToast = (text) =>
    toast.error(text, {
      id: text,
      iconTheme: {
        primary: '#ef4444',
        secondary: '#fff',
      },
      /* icon: '🪄', */
      style: {
        borderRadius: '16px',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 10px 20px 0px',
        fontWeight: '600',
        padding: '12px',
        fontSize: '18px',
        paddingRight: '8px',
        background: '#fff',
        color: '#181818',
      },
    });

  function copyURL() {
    /* Get the text field */
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(`https://typeshare.co/${blogMeta.slug}`);
    setCopied(!copied);
    successToast('Blog URL Copied!');
  }

  function getTheme() {
    const ref = firebase
      .database()
      .ref('gallery/' + blogMeta.slug + '/' + galleryUserID + '/theme/style/');
    ref.once('value').then(function (snapshot) {
      if (snapshot.exists()) {
        setTheme(snapshot.val());
      } else {
      }
    });
  }

  function removeCard(cardID) {
    firebase
      .database()
      .ref('gallery/' + blogMeta.slug + '/' + galleryUserID + '/blog/cards/')
      .child(cardID)
      .remove()
      .then(() => {
        getPrivateCards();
      });
  }

  function saveToDB(key, value) {
    var layouts = JSON.parse(
      JSON.stringify(value, function (k, v) {
        if (v === undefined) {
          return null;
        }
        return v;
      }),
    );

    firebase
      .database()
      .ref('gallery/' + blogMeta.slug + '/' + galleryUserID + '/blog/')
      .update({
        layouts,
      });
  }

  const openEssaySelection = (id) => {
    setEssaySelectorID(id);
    setEssaySelectorOpen(true);
  };

  const openCollectionSelector = (id) => {
    setCollectionSelectorID(id);
    setCollectionSelectorOpen(true);
  };

  const openTextCardModal = (card) => {
    setSelectedTextCardID(card);
    setTextCardEditorOpen(true);
  };

  const openLinkCardModal = (card) => {
    setSelectedLinkCardID(card);
    setLinkCardEditorOpen(true);
  };

  const saveLinkCard = () => {
    getPrivateCards();
    setLinkCardEditorOpen(false);
  };

  const saveTextCard = () => {
    getPrivateCards();
    setTextCardEditorOpen(false);
  };

  function onLayoutChange2(layout, layouts2) {
    if (layouts2 !== layouts)
      if (layoutsLoading === false) {
        loggedInUserID && edit ? saveToDB('layouts', layouts2) : null;
        //loggedInUserID && edit ? setLayouts(layouts2) : null
        console.log('layouts changed');
      }
  }

  function getPrivateCards() {
    const ref = firebase
      .database()
      .ref('gallery/' + blogMeta.slug + '/' + galleryUserID + '/blog/cards/');
    ref
      .once('value')
      .then(function (snapshot) {
        if (snapshot.exists()) {
          var tempCards = [];
          snapshot.forEach((doc) => {
            tempCards.push(doc);
            console.log(doc.val());
          });

          setCardList(tempCards);
        } else {
          setCardList([]);
        }
      })
      .then(() => {
        setLayoutsLoading(false);
        setLoading(false);
      });
  }

  function addCard(cardName, content = null) {
    setOpen(false);

    if (proPlans.includes(productName)) {
      firebase
        .database()
        .ref('gallery/' + blogMeta.slug + '/' + galleryUserID + '/blog/cards/')
        .push({
          type: cardName,
          content,
        })
        .then(() => {
          getPrivateCards();
        });
    } else {
      //not a pro plan user
      if (cardList.length > 4) {
        setProModalOpen(true);
        setProModalMSG(
          'You have reached the maximum number of cards. Please start your Pro plan trial to add more.',
        );
        //already have 5 cards
      } else {
        firebase
          .database()
          .ref(
            'gallery/' + blogMeta.slug + '/' + galleryUserID + '/blog/cards/',
          )
          .push({
            type: cardName,
            content,
          })
          .then(() => {
            getPrivateCards();
          });
      }
    }
  }

  const SidebarNav = ({ mobile }) => {
    return (
      <div
        className={`${
          mobile ? 'flex h-full' : 'hidden md:flex'
        }  relative z-30 w-[300px] min-w-[300px] flex-col items-start justify-between overflow-y-auto border-r-2 border-gray-100 bg-white p-5`}
      >
        <div className="flex h-full w-full flex-col space-y-8">
          <div className="flex w-full flex-row items-center justify-start space-x-2">
            <button
              className="group rounded-[10px] bg-opacity-50 p-2 duration-200 hover:bg-gray-200 hover:bg-opacity-100"
              onClick={() => router.push('/library')}
            >
              <ArrowLeftIcon
                className="h-5 w-5 text-gray-500 group-hover:text-gray-900"
                aria-hidden="true"
              />
            </button>
            <h1 className="text-sm font-medium text-gray-500">Social Blog</h1>
          </div>
          <div className="flex w-full flex-row space-x-1 rounded-lg bg-gray-200 bg-opacity-50 p-0.5">
            <button
              className={`w-full rounded-lg ${
                router.pathname === '/[slug]'
                  ? 'bg-white shadow-sm'
                  : 'hover:bg-gray-200'
              } px-2 py-1.5 text-xs font-medium`}
            >
              Add Content
            </button>

            <button
              onClick={() => router.push('/theme')}
              className={`${
                router.pathname === '/theme'
                  ? 'bg-white shadow-sm'
                  : 'hover:bg-gray-200'
              } w-full rounded-lg px-2 py-1.5 text-xs font-medium duration-200`}
            >
              Theme
            </button>
          </div>
          <div className=" flex w-full flex-1 flex-col items-start justify-between">
            <div className="flex w-full flex-col space-y-8">
              <h1 className="text-lg font-semibold tracking-tight text-gray-900">
                Add Card
              </h1>

              <div className="flex w-full flex-col space-y-2">
                <div className="flex flex-row items-center justify-between font-mono uppercase text-gray-500">
                  <p className="text-xs font-semibold">Basic</p>
                </div>

                <div className="flex w-full flex-col -space-y-0.5">
                  <NavItem
                    click={() => addCard('essayCard')}
                    icon={
                      <div className="-mr-1 flex items-center  justify-center rounded-lg border border-gray-200 bg-gray-50 p-2 text-blue-500">
                        <svg
                          className="h-[14px] w-[14px]"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                          ></path>
                        </svg>
                      </div>
                    }
                  >
                    <div className="flex flex-col items-start justify-start space-y-0">
                      <p>Single Post</p>
                      <p className="text-xs opacity-50">Embed a single post</p>
                    </div>
                  </NavItem>
                  <NavItem
                    click={() => addCard('content-preview')}
                    icon={
                      <div className="-mr-1 flex items-center  justify-center rounded-lg border  border-gray-200 bg-gray-50 p-2 text-blue-500">
                        <svg
                          className="h-[14px] w-[14px]"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M128 152v-32c0-4.4 3.6-8 8-8h208c4.4 0 8 3.6 8 8v32c0 4.4-3.6 8-8 8H136c-4.4 0-8-3.6-8-8zm8 88h208c4.4 0 8-3.6 8-8v-32c0-4.4-3.6-8-8-8H136c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8zm299.1 159.7c-4.2 13-4.2 51.6 0 64.6 7.3 1.4 12.9 7.9 12.9 15.7v16c0 8.8-7.2 16-16 16H80c-44.2 0-80-35.8-80-80V80C0 35.8 35.8 0 80 0h352c8.8 0 16 7.2 16 16v368c0 7.8-5.5 14.2-12.9 15.7zm-41.1.3H80c-17.6 0-32 14.4-32 32 0 17.7 14.3 32 32 32h314c-2.7-17.3-2.7-46.7 0-64zm6-352H80c-17.7 0-32 14.3-32 32v278.7c9.8-4.3 20.6-6.7 32-6.7h320V48z"
                          ></path>
                        </svg>
                      </div>
                    }
                  >
                    {' '}
                    <div className="flex flex-col items-start justify-start space-y-0">
                      <p>All Content</p>
                      <p className="text-xs opacity-50">
                        Scrollable card of all posts
                      </p>
                    </div>
                  </NavItem>
                  <NavItem
                    click={() => addCard('linkCard')}
                    icon={
                      <div className="-mr-1 flex items-center  justify-center rounded-lg border border-gray-200 bg-gray-50 p-2 text-blue-500">
                        <svg
                          className="h-[14px] w-[14px]"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 0 0 4.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 0 0-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 0 1-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 0 1 4.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z"
                          ></path>
                        </svg>
                      </div>
                    }
                  >
                    <div className="flex flex-col items-start justify-start space-y-0">
                      <p>Link Card</p>
                      <p className="text-xs opacity-50">A link to anywhere</p>
                    </div>
                  </NavItem>
                </div>
              </div>

              <div className="flex w-full flex-col space-y-2">
                <div className="flex flex-row items-center justify-between font-mono uppercase text-gray-500">
                  <p className="text-xs font-semibold">Collections</p>
                </div>

                <div className="flex w-full flex-col -space-y-0.5">
                  <NavItem
                    click={() => addCard('collection-link')}
                    icon={
                      <div className="-mr-1 flex items-center  justify-center rounded-lg border border-gray-200 bg-gray-50 p-2 text-orange-500">
                        <svg
                          className="h-[14px] w-[14px]"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M224 48c97.167 0 176 27.723 176 61.714v4.571C400 148.277 321.167 176 224 176S48 148.277 48 114.286v-4.571C48 75.723 126.833 48 224 48m176 135.018v26.399c0 33.991-78.833 61.714-176 61.714S48 243.408 48 209.417v-26.399C85.813 210.982 155.021 224 224 224s138.187-13.018 176-40.982m0 96v26.834c0 33.991-78.833 61.714-176 61.714S48 339.842 48 305.851v-26.834C85.813 306.982 155.021 320 224 320s138.187-13.018 176-40.982m0 96v27.268C400 436.277 321.167 464 224 464S48 436.277 48 402.286v-27.268C85.813 402.982 155.021 416 224 416s138.187-13.018 176-40.982M224 0C137.052 0 0 23.26 0 109.714v292.571C0 488.758 137.03 512 224 512c86.948 0 224-23.26 224-109.714V109.714C448 23.242 310.97 0 224 0z"
                          ></path>
                        </svg>
                      </div>
                    }
                  >
                    <div className="flex flex-col items-start justify-start space-y-0">
                      <p>Collection Link</p>
                      <p className="text-xs opacity-50">Link to a collection</p>
                    </div>
                  </NavItem>
                  <NavItem
                    click={() => addCard('collection-preview')}
                    icon={
                      <div className="-mr-1 flex items-center  justify-center rounded-lg border border-gray-200 bg-gray-50 p-2 text-orange-500">
                        <svg
                          className="h-[14px] w-[14px]"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                        >
                          <path
                            fill="currentColor"
                            d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"
                          ></path>
                        </svg>
                      </div>
                    }
                  >
                    <div className="flex flex-col items-start justify-start space-y-0">
                      <p>Collection Preview</p>
                      <p className="text-xs opacity-50">Preview a collection</p>
                    </div>
                  </NavItem>
                  <NavItem
                    click={() => addCard('essayCollection')}
                    icon={
                      <div className="-mr-1 flex items-center  justify-center rounded-lg border border-gray-200 bg-gray-50 p-2 text-orange-500">
                        <svg
                          className="h-[14px] w-[14px]"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M491.609 73.625l-53.861-53.839c-26.378-26.379-69.076-26.383-95.46-.001L24.91 335.089.329 484.085c-2.675 16.215 11.368 30.261 27.587 27.587l148.995-24.582 315.326-317.378c26.33-26.331 26.581-68.879-.628-96.087zM120.644 302l170.259-169.155 88.251 88.251L210 391.355V350h-48v-48h-41.356zM82.132 458.132l-28.263-28.263 12.14-73.587L84.409 338H126v48h48v41.59l-18.282 18.401-73.586 12.141zm378.985-319.533l-.051.051-.051.051-48.03 48.344-88.03-88.03 48.344-48.03.05-.05.05-.05c9.147-9.146 23.978-9.259 33.236-.001l53.854 53.854c9.878 9.877 9.939 24.549.628 33.861z"
                          ></path>
                        </svg>
                      </div>
                    }
                  >
                    <div className="flex flex-col items-start justify-start space-y-0">
                      <p>All Essays</p>
                      <p className="text-xs opacity-50">
                        A link to all your essays
                      </p>
                    </div>
                  </NavItem>
                  <NavItem
                    click={() => addCard('threadCollection')}
                    icon={
                      <div className="-mr-1 flex items-center  justify-center rounded-lg border border-gray-200 bg-gray-50 p-2 text-orange-500">
                        <svg
                          className="h-[14px] w-[14px]"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M348.5 328.1l-148 148.4c-4.7 4.7-12.3 4.7-17 0l-148-148.4c-4.7-4.7-4.7-12.3 0-17l19.6-19.6c4.8-4.8 12.5-4.7 17.1.2l93.7 97.1V140c0-6.6 5.4-12 12-12h28c6.6 0 12 5.4 12 12v248.8l93.7-97.1c4.7-4.8 12.4-4.9 17.1-.2l19.6 19.6c4.9 4.7 4.9 12.3.2 17zM12 84h360c6.6 0 12-5.4 12-12V44c0-6.6-5.4-12-12-12H12C5.4 32 0 37.4 0 44v28c0 6.6 5.4 12 12 12z"
                          ></path>
                        </svg>
                      </div>
                    }
                  >
                    <div className="flex flex-col items-start justify-start space-y-0">
                      <p>All Threads</p>
                      <p className="text-xs opacity-50">
                        A link to all your threads
                      </p>
                    </div>
                  </NavItem>
                </div>
              </div>

              <div className="flex w-full flex-col space-y-2">
                <div className="flex flex-row items-center justify-between font-mono uppercase text-gray-500">
                  <p className="text-xs font-semibold">Social</p>
                </div>

                <div className="flex w-full flex-col space-y-0">
                  <NavItem
                    click={() => addCard('twitterProfile')}
                    icon={
                      <div className="-mr-1 flex items-center  justify-center rounded-lg border  border-gray-200 bg-gray-50 p-1.5 text-blue-400">
                        <TwitterBirdIcon className={'h-[16px] w-[16px]'} />
                      </div>
                    }
                  >
                    {' '}
                    <div className="flex flex-col items-start justify-start space-y-0">
                      <p>Twitter Profile Link</p>
                      <p className="text-xs opacity-50">Link to your Twitter</p>
                    </div>
                  </NavItem>
                </div>
              </div>
            </div>

            {/*  <NavItem click={() => null} variant={"small-alt"} icon={<svg className="w-[14px] h-[14px] -mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M372.57 112.641v-10.825c0-43.612-40.52-76.691-83.039-65.546-25.629-49.5-94.09-47.45-117.982.747C130.269 26.456 89.144 57.945 89.144 102v126.13c-19.953-7.427-43.308-5.068-62.083 8.871-29.355 21.796-35.794 63.333-14.55 93.153L132.48 498.569a32 32 0 0 0 26.062 13.432h222.897c14.904 0 27.835-10.289 31.182-24.813l30.184-130.958A203.637 203.637 0 0 0 448 310.564V179c0-40.62-35.523-71.992-75.43-66.359zm27.427 197.922c0 11.731-1.334 23.469-3.965 34.886L368.707 464h-201.92L51.591 302.303c-14.439-20.27 15.023-42.776 29.394-22.605l27.128 38.079c8.995 12.626 29.031 6.287 29.031-9.283V102c0-25.645 36.571-24.81 36.571.691V256c0 8.837 7.163 16 16 16h6.856c8.837 0 16-7.163 16-16V67c0-25.663 36.571-24.81 36.571.691V256c0 8.837 7.163 16 16 16h6.856c8.837 0 16-7.163 16-16V101.125c0-25.672 36.57-24.81 36.57.691V256c0 8.837 7.163 16 16 16h6.857c8.837 0 16-7.163 16-16v-76.309c0-26.242 36.57-25.64 36.57-.691v131.563z"></path></svg>}>Drag cards to re-arrange</NavItem> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {exists ? (
        <>
          <head>
            <base target="_blank"></base>
            <meta name="twitter:site" content="@typeshare_co" />
            <meta
              name="twitter:title"
              content={`${
                blogMeta.displayName
                  ? blogMeta.displayName + ' | Welcome to my Social Blog'
                  : 'Not Found'
              }`}
            />
            <meta name="twitter:description" content={`${blogMeta.bio}`} />
            <meta
              name="twitter:creator"
              content={`${blogMeta.twitterHandle}`}
            />
            <meta name="twitter:image" content={ogImage} />
            <meta name="twitter:card" content="summary_large_image" />
          </head>
          <NextSeo
            title={`${
              blogMeta.displayName ? 'Welcome to my Social Blog' : 'Not Found'
            }`}
            titleTemplate={`${
              blogMeta.displayName ? blogMeta.displayName : null
            } | %s`}
            description={`${blogMeta.bio}`}
            additionalLinkTags={[
              {
                rel: 'icon',
                href: '/favicon.ico',
              },
            ]}
            openGraph={{
              type: 'website',
              title: `${
                blogMeta.displayName
                  ? blogMeta.displayName + ' | Welcome to my Social Blog'
                  : 'Not Found'
              }`,
              description: `${blogMeta.bio ? blogMeta.bio : 'Not Found'}`,
              url:
                'https://typeshare.co/' +
                `${blogMeta.slug ? blogMeta.slug : null}`,
              images: [
                {
                  url: ogImage,
                  width: 800,
                  height: 600,
                  alt: 'Og Image Alt',
                },
              ],
            }}
          />

          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-20 overflow-hidden"
              onClose={setOpen}
            >
              <div className="absolute inset-0 overflow-hidden">
                <Dialog.Overlay className="absolute inset-0" />

                <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <div className="pointer-events-auto">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="relative flex-1">
                          <SidebarNav mobile={true} />
                        </div>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {galleryUserID === loggedInUserID ? (
            <EditProfile
              isOpen={profileModalOpen}
              setIsOpen={setProfileModalOpen}
              gallerySlug={gallerySlug}
              user={loggedInUser}
              setIsReady={(val1, val2) => refreshData(val2)}
            />
          ) : null}

          {blogMeta !== undefined || blogMeta !== null ? (
            <div className="flex max-h-screen min-h-screen w-screen flex-col overflow-hidden antialiased">
              {galleryUserID === loggedInUserID && (
                <div className="relative z-10 flex w-full flex-row items-center justify-between space-x-2 bg-gray-100 px-4 py-4 sm:px-8 md:hidden">
                  <div className="flex flex-row items-center justify-start space-x-2">
                    <button
                      className="group rounded-[10px] bg-opacity-50 p-2 duration-200 hover:bg-gray-200 hover:bg-opacity-100"
                      onClick={() => setOpen(true)}
                    >
                      <MenuIcon
                        className="h-5 w-5 text-gray-500 group-hover:text-gray-900"
                        aria-hidden="true"
                      />
                    </button>
                    <h1 className="text-base font-medium text-gray-900">
                      Social Blog
                    </h1>
                  </div>
                  <Button click={() => setOpen(true)} size={'mini'}>
                    Edit
                  </Button>
                </div>
              )}

              <div className={'flex flex-1 flex-row overflow-hidden'}>
                {galleryUserID === loggedInUserID && <SidebarNav />}

                <div
                  className={`w-full flex-1 bg-gray-50 ${
                    galleryUserID === loggedInUserID ? 'p-0 sm:p-8' : 'p-0'
                  } overflow-hidden`}
                >
                  <div
                    style={{
                      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 10px 40px 0px',
                    }}
                    className={`flex h-full w-full  flex-1 flex-col overflow-y-auto sm:overflow-hidden ${
                      galleryUserID === loggedInUserID &&
                      'mx-auto max-w-[1400px] rounded-none border-gray-100 sm:rounded-xl sm:border'
                    }`}
                  >
                    {galleryUserID === loggedInUserID && (
                      <div className="hidden flex-row items-center justify-between border-b border-gray-100 bg-white px-4 py-2 sm:flex">
                        <div className="flex flex-row space-x-1">
                          <div className="h-2 w-2 rounded-full bg-red-500" />
                          <div className="h-2 w-2 rounded-full bg-yellow-500" />
                          <div className="h-2 w-2 rounded-full bg-green-500" />
                        </div>

                        <div className="flex flex-row items-center justify-center space-x-1">
                          <LockClosedIcon className={'h-3 w-3 text-gray-500'} />

                          {blogMeta.slug ? (
                            <p className="text-left font-sans text-sm font-medium text-gray-600">
                              typeshare.co/{blogMeta.slug}
                            </p>
                          ) : (
                            <div className="h-3 w-24 animate-pulse rounded-md bg-gray-200" />
                          )}
                        </div>

                        <div className="flex flex-row space-x-0 ">
                          <button
                            onClick={() => setActiveWidth('sm')}
                            className={` ${
                              activeWidth === 'sm'
                                ? 'text-blue-500'
                                : 'text-gray-500 hover:text-gray-900'
                            } flex items-center justify-center rounded-full p-2 duration-200 hover:bg-gray-100 `}
                          >
                            <DeviceMobileIcon className={'h-[18px] w-[18px]'} />
                          </button>
                          <button
                            onClick={() => setActiveWidth('md')}
                            className={` ${
                              activeWidth === 'md'
                                ? 'text-blue-500'
                                : 'text-gray-500 hover:text-gray-900'
                            } flex items-center justify-center rounded-full p-2 duration-200 hover:bg-gray-100 `}
                          >
                            <DeviceTabletIcon className={'h-[18px] w-[18px]'} />
                          </button>
                          <button
                            onClick={() => setActiveWidth('lg')}
                            className={` ${
                              activeWidth === 'lg'
                                ? 'text-blue-500'
                                : 'text-gray-500 hover:text-gray-900'
                            } flex items-center justify-center rounded-full p-2 duration-200 hover:bg-gray-100 `}
                          >
                            <DesktopComputerIcon
                              className={'h-[18px] w-[18px]'}
                            />
                          </button>
                        </div>
                      </div>
                    )}

                    <div
                      className={`overflow-y-auto ${
                        galleryUserID === loggedInUserID && 'sm:rounded-b-xl'
                      }`}
                    >
                      <main
                        className={
                          'relative flex w-full flex-1 flex-col items-center bg-gray-50 font-sans antialiased '
                        }
                      >
                        <div
                          className={
                            'relative flex h-80 max-h-80 w-full items-start justify-center'
                          }
                        >
                          {blogMeta.coverImage ? (
                            <Image
                              layout={'fill'}
                              className={
                                'absolute top-0 h-80 max-h-80 w-full object-cover'
                              }
                              src={blogMeta.coverImage}
                            />
                          ) : (
                            <img
                              className={
                                'absolute top-0 h-80 max-h-80 w-full object-cover'
                              }
                              src={'/default-header.png'}
                            />
                          )}

                          {loggedInUserID === galleryUserID ? null : (
                            <PostTopBar type={'blog'} creatorMeta={blogMeta} />
                          )}

                          {loggedInUserID === galleryUserID ? null : (
                            <div
                              style={{
                                background:
                                  'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 100%)',
                              }}
                              className={' absolute top-0 h-80 max-h-80 w-full'}
                            />
                          )}
                        </div>

                        <div
                          className={` relative -mt-24 w-full flex-row items-center justify-between ${
                            activeWidth === 'lg'
                              ? 'max-w-[1200px]'
                              : activeWidth === 'md'
                              ? 'max-w-2xl'
                              : 'max-w-md'
                          } px-8`}
                        >
                          <div
                            className={'mb-6 mr-auto flex flex-col items-start'}
                          >
                            <div
                              className={
                                'flex h-40 w-40 flex-row items-end justify-start rounded-full border-8 border-gray-50'
                              }
                            >
                              <Image
                                width={160}
                                height={160}
                                className={' rounded-full object-cover'}
                                src={optimizedImage}
                              />
                              <div
                                className={`${
                                  blogMeta.badges !== undefined
                                    ? 'flex'
                                    : 'hidden'
                                } relative z-10 -ml-12 flex-row  -space-x-2 `}
                              >
                                {blogMeta.badges !== undefined &&
                                blogMeta.badges !== null ? (
                                  blogMeta.badges.earlyAdopterPin ? (
                                    <img
                                      className="h-12 w-12"
                                      src={'/early-adopter-pin.png'}
                                    />
                                  ) : null
                                ) : null}
                              </div>
                            </div>
                            <div className={'mb-2 mt-4 flex flex-col'}>
                              <div
                                className={
                                  'mb-1 flex flex-row items-center justify-start space-x-2'
                                }
                              >
                                <p
                                  className={
                                    ' font-sans text-3xl font-bold tracking-tight'
                                  }
                                >
                                  {blogMeta.displayName}
                                </p>
                                {blogMeta.verified ? (
                                  <VerifiedIcon
                                    className={
                                      'mt-1 h-5 w-5 fill-current text-blue-500'
                                    }
                                  />
                                ) : null}
                              </div>

                              {/*  <div className='text-sm flex flex-row space-x-4 mb-4 text-gray-500'>
                                                            <p>
                                                                @sam
                                                            </p>
                                                            <div className='flex flex-row items-center justify-start space-x-1'>

                                                                <MapIcon className={"w-4 h-4 text-gray-500"} />
                                                                <p>
                                                                    Canada
                                                                </p>

                                                            </div>
                                                            <div className='flex flex-row items-center justify-start space-x-1'>

                                                                <CalendarIcon className={"w-4 h-4 text-gray-500"} />
                                                                <p>
                                                                    Joined 2 years ago
                                                                </p>

                                                            </div>


                                                        </div> */}

                              <p className={' max-w-3xl text-gray-600'}>
                                {blogMeta.bio}
                              </p>

                              {/* <div className='w-[1136px] bg-gray-100 rounded-2xl p-10 py-24 mt-4 '>

                                                        </div> */}

                              {/* <div className='text-sm flex flex-row space-x-4 mt-4 text-gray-500'>

                                                            <div className='flex flex-row items-center justify-start space-x-1 border rounded-full border-gray-200 text-gray-700 px-2 py-1'>

                                                                <p>🤔</p>
                                                                <p>
                                                                    Development
                                                                </p>

                                                            </div>

                                                            <div className='flex flex-row items-center justify-start space-x-1 border rounded-full border-gray-200 text-gray-700 px-2 py-1'>

                                                                <p>🎨</p>
                                                                <p>
                                                                    Design
                                                                </p>

                                                            </div>

                                                            <div className='flex flex-row items-center justify-start space-x-1 border rounded-full border-gray-200 text-gray-700 px-2 py-1'>

                                                                <p>💻</p>
                                                                <p>
                                                                    Software
                                                                </p>

                                                            </div>


                                                        </div> */}

                              <div
                                className={
                                  'mt-4 flex flex-row space-x-2 pr-8 sm:hidden xl:pr-0'
                                }
                              >
                                {loggedInUserID === galleryUserID ? null : (
                                  <a
                                    href={`https://twitter.com/${blogMeta.twitterURL}`}
                                    target={'_blank'}
                                    rel="noreferrer noopener"
                                    className={
                                      'flex transform cursor-pointer flex-row space-x-2 rounded-full bg-gray-900 p-2 px-4 font-semibold text-white transition duration-200 hover:scale-105 active:scale-100'
                                    }
                                  >
                                    <TwitterBirdIcon
                                      className={'h-6 w-6 text-white'}
                                    />
                                    <p>Follow {blogMeta.displayName}</p>
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            className={
                              'absolute top-[76px] right-0 hidden flex-row space-x-2 pr-8 sm:flex xl:pr-0'
                            }
                          >
                            {loggedInUserID === galleryUserID ? null : (
                              <a
                                href={`https://twitter.com/${blogMeta.twitterURL}`}
                                target={'_blank'}
                                rel="noreferrer noopener"
                                className={
                                  'flex transform cursor-pointer flex-row space-x-2 rounded-full bg-gray-900 p-2 px-4 font-semibold text-white transition duration-200 hover:scale-105 active:scale-100'
                                }
                              >
                                <TwitterBirdIcon
                                  className={'h-6 w-6 text-white'}
                                />
                                <p>Follow {blogMeta.displayName}</p>
                              </a>
                            )}
                          </div>

                          <div className={'w-full max-w-[1200px]'}>
                            {!loading && !layoutsLoading ? (
                              <div className={'w-full'}>
                                <>
                                  <UpgradeModal
                                    msg={proModalMSG}
                                    isOpen={proModalOpen}
                                    setIsOpen={setProModalOpen}
                                  />
                                  {layoutsLoading ? (
                                    <div
                                      className={
                                        'grid w-full grid-cols-1 items-center justify-center gap-4 md:grid-cols-2 lg:grid-cols-4'
                                      }
                                    >
                                      <EssayLoadingState />
                                      <EssayLoadingState />
                                      <EssayLoadingState />
                                      <EssayLoadingState />
                                    </div>
                                  ) : (
                                    <div
                                      className={
                                        'relative my-8 flex h-full w-full flex-col items-center justify-center antialiased'
                                      }
                                    >
                                      {edit ? (
                                        <>
                                          <EssaySelectorModal
                                            fetchPosts={() =>
                                              fetchPosts(
                                                galleryUserID,
                                                blogMeta.slug,
                                              )
                                            }
                                            open={isEssaySelectorOpen}
                                            setOpen={setEssaySelectorOpen}
                                            activeBlock={essaySelectorID}
                                            posts={posts}
                                            next={next}
                                            selectedEssay={(essayID, type) =>
                                              selectedEssay(essayID, type)
                                            }
                                            creatorSlug={blogMeta.slug}
                                            creatorID={loggedInUserID}
                                          />
                                          <TextCardEditor
                                            slug={blogMeta.slug}
                                            refreshCards={() => saveTextCard()}
                                            loggedInUserID={loggedInUserID}
                                            card={selectedTextCardID}
                                            open={isTextCardEditorOpen}
                                            setOpen={setTextCardEditorOpen}
                                          />
                                          <LinkCardEditor
                                            slug={blogMeta.slug}
                                            refreshCards={() => saveLinkCard()}
                                            loggedInUserID={loggedInUserID}
                                            card={selectedLinkCardID}
                                            open={isLinkCardEditorOpen}
                                            setOpen={setLinkCardEditorOpen}
                                          />

                                          <CollectionSelectorModal
                                            open={isCollecionSelectorOpen}
                                            setOpen={setCollectionSelectorOpen}
                                            activeBlock={collectionSelectorID}
                                            selectedCollection={(essayID) =>
                                              selectedCollection(essayID)
                                            }
                                            slug={blogMeta.slug}
                                            creatorID={loggedInUserID}
                                          />
                                        </>
                                      ) : null}

                                      {cardList.length > 0 ? (
                                        <div
                                          className={'relative w-full pb-16'}
                                        >
                                          <SizeMe>
                                            {({ size }) => (
                                              <ResponsiveReactGridLayout
                                                containerPadding={[0, 0]}
                                                width={size.width}
                                                className="layout"
                                                cols={{
                                                  lg: 4,
                                                  md: 4,
                                                  sm: 2,
                                                  xs: 2,
                                                  xxs: 1,
                                                }}
                                                rowHeight={45}
                                                isResizable={false}
                                                isDraggable={edit}
                                                isBounded={true}
                                                layouts={layouts}
                                                margin={[16, 16]}
                                                onLayoutChange={(
                                                  layout,
                                                  layouts,
                                                ) =>
                                                  onLayoutChange2(
                                                    layout,
                                                    layouts,
                                                  )
                                                }
                                              >
                                                {cardList.map((card, i) =>
                                                  card.val().type ===
                                                  'twitterCard' ? (
                                                    <div
                                                      key={card.key}
                                                      style={{
                                                        boxShadow:
                                                          '0px 0px 34px -2px rgba(0, 0, 0, 0.025)',
                                                      }}
                                                      data-grid={{
                                                        w: 1,
                                                        h: 3,
                                                        x: i % 4,
                                                        y: 100,
                                                        minW: 1,
                                                        minH: 3,
                                                        maxW: 2,
                                                        maxH: 6,
                                                        isResizable: true,
                                                      }}
                                                    >
                                                      <TwitterSocialBlogCard
                                                        handle={
                                                          blogMeta.twitterURL
                                                        }
                                                      />
                                                    </div>
                                                  ) : card.val().type ===
                                                    'profileCard' ? (
                                                    <div
                                                      key={card.key}
                                                      style={{
                                                        boxShadow:
                                                          '0px 0px 34px -2px rgba(0, 0, 0, 0.025)',
                                                      }}
                                                      data-grid={{
                                                        w: 2,
                                                        h: 4,
                                                        x: 0,
                                                        y: 0,
                                                        minW: 4,
                                                        minH: 4,
                                                      }}
                                                      className={
                                                        'overflow-y-scroll rounded-2xl bg-white'
                                                      }
                                                    >
                                                      <ProfileCard
                                                        blogMeta={blogMeta}
                                                        edit={edit}
                                                        isResizable={false}
                                                      />
                                                    </div>
                                                  ) : card.val().type ===
                                                    'quoteCard' ? (
                                                    <div
                                                      key={card.key}
                                                      style={{
                                                        boxShadow:
                                                          '0px 0px 34px -2px rgba(0, 0, 0, 0.025)',
                                                      }}
                                                      data-grid={{
                                                        w: 2,
                                                        h: 6,
                                                        x: i % 4,
                                                        y: 100,
                                                        minW: 2,
                                                        minH: 3,
                                                        isResizable: true,
                                                      }}
                                                      className={
                                                        'rounded-2xl bg-white'
                                                      }
                                                    >
                                                      <QuoteSocialBlogCard
                                                        title={
                                                          card.val().content
                                                            .title
                                                        }
                                                        edit={edit}
                                                        removeCard={() =>
                                                          removeCard(card.key)
                                                        }
                                                      />
                                                    </div>
                                                  ) : card.val().type ===
                                                    'textCard' ? (
                                                    <div
                                                      key={card.key}
                                                      style={{
                                                        boxShadow:
                                                          '0px 0px 34px -2px rgba(0, 0, 0, 0.025)',
                                                      }}
                                                      data-grid={{
                                                        w: 1,
                                                        h: 6,
                                                        x: i % 4,
                                                        y: 100,
                                                        minW: 1,
                                                        minH: 3,
                                                        isResizable: true,
                                                      }}
                                                      className={
                                                        'overflow-y-scroll rounded-2xl bg-white'
                                                      }
                                                    >
                                                      <TextSocialBlogCard
                                                        saveCard={(
                                                          bg,
                                                          title,
                                                          desc,
                                                          link,
                                                        ) =>
                                                          saveTextCard(
                                                            card.key,
                                                            bg,
                                                            title,
                                                            desc,
                                                            link,
                                                          )
                                                        }
                                                        editTextCard={() =>
                                                          openTextCardModal(
                                                            card,
                                                          )
                                                        }
                                                        card={
                                                          card.val().content
                                                        }
                                                        edit={edit}
                                                        removeCard={() =>
                                                          removeCard(card.key)
                                                        }
                                                      />
                                                    </div>
                                                  ) : card.val().type ===
                                                    'linkCard' ? (
                                                    <div
                                                      key={card.key}
                                                      id={card.key}
                                                      style={{
                                                        boxShadow:
                                                          '0px 0px 34px -2px rgba(0, 0, 0, 0.025)',
                                                      }}
                                                      data-grid={{
                                                        w: 1,
                                                        h: 4,
                                                        x: i % 4,
                                                        y: 100,
                                                        minH: 4,
                                                        minW: 1,
                                                      }}
                                                      className={
                                                        'rounded-2xl bg-white'
                                                      }
                                                    >
                                                      <LinkSocialBlogCard
                                                        saveCard={(
                                                          bg,
                                                          title,
                                                          desc,
                                                          link,
                                                          image,
                                                        ) =>
                                                          saveLinkCard(
                                                            card.key,
                                                            title,
                                                            desc,
                                                            link,
                                                            image,
                                                          )
                                                        }
                                                        editCard={() =>
                                                          openLinkCardModal(
                                                            card,
                                                          )
                                                        }
                                                        card={
                                                          card.val().content
                                                        }
                                                        edit={edit}
                                                        removeCard={() =>
                                                          removeCard(card.key)
                                                        }
                                                      />
                                                    </div>
                                                  ) : card.val().type ===
                                                    'collection-preview' ? (
                                                    <div
                                                      key={card.key}
                                                      style={{
                                                        boxShadow:
                                                          '0px 0px 34px -2px rgba(0, 0, 0, 0.025)',
                                                      }}
                                                      data-grid={{
                                                        w: 4,
                                                        h: 8.5,
                                                        x: i % 4,
                                                        y: 100,
                                                        minW: 4,
                                                        minH: 8.5,
                                                      }}
                                                      className={
                                                        ' overflow-hidden rounded-2xl bg-white'
                                                      }
                                                    >
                                                      <CollectionPreviewRouterCard
                                                        creatorMeta={blogMeta}
                                                        userID={galleryUserID}
                                                        slug={blogMeta.slug}
                                                        collectionID={
                                                          card.hasChild(
                                                            'content',
                                                          )
                                                            ? card.val().content
                                                                .id
                                                            : null
                                                        }
                                                        openCollectionSelector={() =>
                                                          openCollectionSelector(
                                                            card.key,
                                                          )
                                                        }
                                                        edit={edit}
                                                        removeCard={() =>
                                                          removeCard(card.key)
                                                        }
                                                      />
                                                    </div>
                                                  ) : card.val().type ===
                                                    'content-preview' ? (
                                                    <div
                                                      key={card.key}
                                                      style={{
                                                        boxShadow:
                                                          '0px 0px 34px -2px rgba(0, 0, 0, 0.025)',
                                                      }}
                                                      data-grid={{
                                                        w: 4,
                                                        h: 8.5,
                                                        x: i % 4,
                                                        y: 100,
                                                        minW: 4,
                                                        minH: 8.5,
                                                      }}
                                                      className={
                                                        'overflow-hidden rounded-2xl bg-white'
                                                      }
                                                    >
                                                      <ContentCard
                                                        removeCard={() =>
                                                          removeCard(card.key)
                                                        }
                                                        colllectionCreatorID={
                                                          galleryUserID
                                                        }
                                                        colllectionCreatorSlug={
                                                          blogMeta.slug
                                                        }
                                                        edit={edit}
                                                        blogMeta={blogMeta}
                                                        typeshares={typeshares}
                                                        threads={threads}
                                                      />
                                                    </div>
                                                  ) : card.val().type ===
                                                    'essayCollection' ? (
                                                    <div
                                                      key={card.key}
                                                      style={{
                                                        boxShadow:
                                                          '0px 0px 34px -2px rgba(0, 0, 0, 0.025)',
                                                      }}
                                                      data-grid={{
                                                        w: 1,
                                                        h: 6,
                                                        x: i % 4,
                                                        y: 100,
                                                        minH: 6,
                                                        minW: 1,
                                                      }}
                                                      className={''}
                                                    >
                                                      <EssayCollectionCard
                                                        typeshares={typeshares}
                                                        theme={theme}
                                                        edit={edit}
                                                        userID={galleryUserID}
                                                        removeCard={() =>
                                                          removeCard(card.key)
                                                        }
                                                        slug={blogMeta.slug}
                                                      />
                                                    </div>
                                                  ) : card.val().type ===
                                                    'threadCollection' ? (
                                                    <div
                                                      key={card.key}
                                                      style={{
                                                        boxShadow:
                                                          '0px 0px 34px -2px rgba(0, 0, 0, 0.025)',
                                                      }}
                                                      data-grid={{
                                                        w: 1,
                                                        h: 6,
                                                        x: i % 4,
                                                        y: 100,
                                                        minH: 6,
                                                        minW: 1,
                                                      }}
                                                      className={' rounded-2xl'}
                                                    >
                                                      <ThreadCollectionCard
                                                        blogMeta={blogMeta}
                                                        threads={threads}
                                                        theme={theme}
                                                        edit={edit}
                                                        userID={galleryUserID}
                                                        removeCard={() =>
                                                          removeCard(card.key)
                                                        }
                                                        slug={blogMeta.slug}
                                                      />
                                                    </div>
                                                  ) : card.val().type ===
                                                    'essays' ? (
                                                    <div
                                                      key={card.key}
                                                      style={{
                                                        boxShadow:
                                                          '0px 0px 34px -2px rgba(0, 0, 0, 0.025)',
                                                      }}
                                                      data-grid={{
                                                        w: 2,
                                                        h: 6,
                                                        x: i % 4,
                                                        y: 100,
                                                        minW: 2,
                                                        minH: 3,
                                                      }}
                                                      className={
                                                        'overflow-x-scroll rounded-2xl bg-white'
                                                      }
                                                    >
                                                      <EssayCollectionCard
                                                        edit={edit}
                                                      />
                                                    </div>
                                                  ) : card.val().type ===
                                                    'essayCard' ? (
                                                    <div
                                                      key={card.key}
                                                      /* style={{ boxShadow: "0px 0px 34px -2px rgba(0, 0, 0, 0.025)" }} */ data-grid={{
                                                        w: 1,
                                                        h: 6,
                                                        x: i % 4,
                                                        y: 100,
                                                        minH: 6,
                                                        minW: 1,
                                                      }}
                                                      className={
                                                        'rounded-2xl bg-gray-100'
                                                      }
                                                    >
                                                      <EssaySocialBlogCard
                                                        userID={galleryUserID}
                                                        slug={blogMeta.slug}
                                                        typeshareID={
                                                          card.hasChild(
                                                            'content',
                                                          )
                                                            ? card.val().content
                                                                .essayID
                                                            : null
                                                        }
                                                        openEssaySelector={() =>
                                                          openEssaySelection(
                                                            card.key,
                                                          )
                                                        }
                                                        edit={edit}
                                                        removeCard={() =>
                                                          removeCard(card.key)
                                                        }
                                                      />
                                                    </div>
                                                  ) : card.val().type ===
                                                    'thread' ? (
                                                    <div
                                                      key={card.key}
                                                      /* style={{ boxShadow: "0px 0px 34px -2px rgba(0, 0, 0, 0.025)" }} */ data-grid={{
                                                        w: 1,
                                                        h: 6,
                                                        x: i % 4,
                                                        y: 100,
                                                        minH: 6,
                                                        minW: 1,
                                                      }}
                                                      className={
                                                        'rounded-2xl bg-gray-100'
                                                      }
                                                    >
                                                      <ThreadSocialBlogCard
                                                        socialBlog={true}
                                                        openThreadSelector={() =>
                                                          openThreadSelector(
                                                            card.key,
                                                          )
                                                        }
                                                        edit={edit}
                                                        removeCard={() =>
                                                          removeCard(card.key)
                                                        }
                                                        threadID={
                                                          card.hasChild(
                                                            'content',
                                                          )
                                                            ? card.val().content
                                                                .id
                                                            : null
                                                        }
                                                        userMatch={true}
                                                        creatorID={
                                                          galleryUserID
                                                        }
                                                        creatorSlug={
                                                          blogMeta.slug
                                                        }
                                                        creatorMeta={blogMeta}
                                                      />
                                                    </div>
                                                  ) : card.val().type ===
                                                    'headingCard' ? (
                                                    <div
                                                      key={card.key}
                                                      data-grid={{
                                                        w: 4,
                                                        h: 1,
                                                        x: i % 4,
                                                        y: 100,
                                                        minH: 1,
                                                        minW: 1,
                                                        maxH: 1,
                                                        maxW: 4,
                                                        isResizable: true,
                                                      }}
                                                      className={
                                                        ' group relative flex cursor-move items-center justify-start border-b border-gray-200 p-2'
                                                      }
                                                    >
                                                      <input
                                                        placeholder={
                                                          'My Heading...'
                                                        }
                                                        className={
                                                          'border-transparent bg-transparent font-primary text-2xl font-semibold outline-none focus:outline-none'
                                                        }
                                                      />
                                                      <button
                                                        onClick={() =>
                                                          removeCard(card.key)
                                                        }
                                                        className={
                                                          'absolute -left-4 hidden rounded-full bg-red-500 bg-opacity-10 text-red-500 group-hover:flex'
                                                        }
                                                      >
                                                        <XIcon className="h-4 w-4" />
                                                      </button>
                                                    </div>
                                                  ) : card.val().type ===
                                                    'collection-link' ? (
                                                    <div
                                                      key={card.key}
                                                      style={{
                                                        boxShadow:
                                                          '0px 0px 34px -2px rgba(0, 0, 0, 0.025)',
                                                      }}
                                                      data-grid={{
                                                        w: 1,
                                                        h: 4,
                                                        x: i % 4,
                                                        y: 100,
                                                        minH: 4,
                                                        minW: 1,
                                                      }}
                                                      className={
                                                        'rounded-2xl bg-gray-100'
                                                      }
                                                    >
                                                      <CollectionRouterCard
                                                        userID={galleryUserID}
                                                        slug={blogMeta.slug}
                                                        collectionID={
                                                          card.hasChild(
                                                            'content',
                                                          )
                                                            ? card.val().content
                                                                .id
                                                            : null
                                                        }
                                                        openCollectionSelector={() =>
                                                          openCollectionSelector(
                                                            card.key,
                                                          )
                                                        }
                                                        edit={edit}
                                                        removeCard={() =>
                                                          removeCard(card.key)
                                                        }
                                                      />
                                                    </div>
                                                  ) : /*  : card.val().type === "streakCard" ?
                                                                     
                                                                                                                                                         <div key={card.key} style={{ boxShadow: "0px 0px 34px -2px rgba(0, 0, 0, 0.025)" }} data-grid={{ w: 1, h: 2, x: i%4, y: 100, minW: 1, minH: 2, maxW: 2, maxH: 6 }} className={"overflow-hidden rounded-3xl bg-white"}>
                                                                                                                                                             <StreakSocialBlogCard essays={typeshares} threads={threads} edit={edit} removeCard={() => removeCard(card.key)} />
                                                                                                                                                         </div> */

                                                  card.val().type ===
                                                    'featuredEssays' ? (
                                                    <div
                                                      key={card.key}
                                                      style={{
                                                        boxShadow:
                                                          '0px 0px 34px -2px rgba(0, 0, 0, 0.025)',
                                                      }}
                                                      data-grid={{
                                                        w: 12,
                                                        h: 16,
                                                        x: 0,
                                                        y: 2,
                                                        minW: 2,
                                                        minH: 3,
                                                      }}
                                                      className={
                                                        'overflow-x-scroll rounded-2xl bg-white'
                                                      }
                                                    >
                                                      <FeaturedEssaysCard
                                                        blogMeta={blogMeta}
                                                        typeshares={typeshares}
                                                      />
                                                    </div>
                                                  ) : card.val().type ===
                                                    'twitterProfile' ? (
                                                    <div
                                                      key={card.key}
                                                      style={{
                                                        boxShadow:
                                                          '0px 0px 34px -2px rgba(0, 0, 0, 0.025)',
                                                      }}
                                                      data-grid={{
                                                        w: 1,
                                                        h: 2,
                                                        x: i % 4,
                                                        y: 100,
                                                        minW: 1,
                                                        minH: 2,
                                                        maxW: 2,
                                                        maxH: 6,
                                                      }}
                                                      className={
                                                        'rounded-2xl bg-white'
                                                      }
                                                    >
                                                      <TwitterSocialBlogCard
                                                        handle={
                                                          blogMeta.twitterURL
                                                        }
                                                        edit={edit}
                                                        removeCard={() =>
                                                          removeCard(card.key)
                                                        }
                                                      />
                                                    </div>
                                                  ) : null,
                                                )}
                                              </ResponsiveReactGridLayout>
                                            )}
                                          </SizeMe>
                                        </div>
                                      ) : edit ? (
                                        <div
                                          className={
                                            'mb-16 flex w-full flex-col space-y-8'
                                          }
                                        >
                                          <EmptyStateSection
                                            icon={
                                              <svg
                                                width="40"
                                                height="40"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                              >
                                                <path
                                                  stroke="currentColor"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth="1.5"
                                                  d="M4.75 5.75C4.75 5.19772 5.19772 4.75 5.75 4.75H9.25C9.80228 4.75 10.25 5.19772 10.25 5.75V9.25C10.25 9.80228 9.80228 10.25 9.25 10.25H5.75C5.19772 10.25 4.75 9.80228 4.75 9.25V5.75Z"
                                                ></path>
                                                <path
                                                  stroke="currentColor"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth="1.5"
                                                  d="M4.75 14.75C4.75 14.1977 5.19772 13.75 5.75 13.75H9.25C9.80228 13.75 10.25 14.1977 10.25 14.75V18.25C10.25 18.8023 9.80228 19.25 9.25 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V14.75Z"
                                                ></path>
                                                <path
                                                  stroke="currentColor"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth="1.5"
                                                  d="M13.75 5.75C13.75 5.19772 14.1977 4.75 14.75 4.75H18.25C18.8023 4.75 19.25 5.19772 19.25 5.75V9.25C19.25 9.80228 18.8023 10.25 18.25 10.25H14.75C14.1977 10.25 13.75 9.80228 13.75 9.25V5.75Z"
                                                ></path>
                                                <path
                                                  stroke="currentColor"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth="1.5"
                                                  d="M13.75 14.75C13.75 14.1977 14.1977 13.75 14.75 13.75H18.25C18.8023 13.75 19.25 14.1977 19.25 14.75V18.25C19.25 18.8023 18.8023 19.25 18.25 19.25H14.75C14.1977 19.25 13.75 18.8023 13.75 18.25V14.75Z"
                                                ></path>
                                              </svg>
                                            }
                                            title={
                                              'Add a card to build your Social Blog'
                                            }
                                            text={
                                              'Use the sidebar to add some content to your Social Blog.'
                                            }
                                            noCTA={true}
                                          />

                                          {threads.length !== 0 ||
                                          typeshares.length !== 0 ? (
                                            <>
                                              <div
                                                className={
                                                  'relative flex w-full items-center justify-center text-gray-600'
                                                }
                                              >
                                                <div
                                                  className={
                                                    'absolute top-1/2 h-[1px] w-full -translate-y-1/2 transform bg-gray-200'
                                                  }
                                                />
                                                <div
                                                  className={
                                                    'z-10 flex flex-row items-center justify-center bg-gray-50 px-4'
                                                  }
                                                >
                                                  <p>
                                                    What visitors currently see
                                                  </p>
                                                  <ArrowDownIcon
                                                    className={'ml-2 h-4 w-4'}
                                                  />
                                                </div>
                                              </div>
                                              <div
                                                className={
                                                  'w-full space-y-8 antialiased'
                                                }
                                              >
                                                {/* 
                                        <HorizontalTabs tab={filter} setTab={(val) => setFilter(val)} /> */}

                                                {
                                                  /* filter === "threads" ?
    
                                                threads.length === 0 ?
    
    
                                                    <div className={"w-full col-span-4"}>
                                                        <EmptyStateSection icon={<ThreadIconDetailed className={"w-12 h-12"} />} title={"No Published Threads"} text={`${blogMeta.displayName} hasn't published any threads yet.`} noCTA={true} />
                                                    </div>
    
    
    
                                                    :
    
    
                                                    threads.map((thread, i) => (
                                                        <ThreadSocialBlogCard edit={false} removeCard={() => null} threadID={thread.key} userMatch={true} creatorID={galleryUserID} creatorSlug={blogMeta.slug} creatorMeta={blogMeta} />
    
    
    
                                                    ))
    
                                                :  */ posts.length === 0 ? (
                                                    <div className={'w-full'}>
                                                      <EmptyStateSection
                                                        icon={
                                                          <EssayIconDetailed
                                                            className={
                                                              'h-12 w-12 text-gray-400'
                                                            }
                                                          />
                                                        }
                                                        title={
                                                          'No Published Posts'
                                                        }
                                                        text={`${blogMeta.displayName} hasn't published any posts yet.`}
                                                        noCTA={true}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className={
                                                        'flex w-full flex-col items-center justify-center space-y-8'
                                                      }
                                                    >
                                                      <div
                                                        className={`grid w-full ${
                                                          activeWidth === 'lg'
                                                            ? filter ===
                                                              'threads'
                                                              ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4'
                                                              : 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'
                                                            : activeWidth ===
                                                              'md'
                                                            ? 'grid-cols-2'
                                                            : 'grid-cols-1'
                                                        } gap-4`}
                                                      >
                                                        {posts.map(
                                                          (typeshare, i) => (
                                                            <EssayCard
                                                              key={typeshare.id}
                                                              creatorID={
                                                                galleryUserID
                                                              }
                                                              creatorSlug={
                                                                blogMeta.slug
                                                              }
                                                              typeshare={
                                                                typeshare
                                                              }
                                                              click={() =>
                                                                typeshare?.val
                                                                  ?.tweetArray
                                                                  ? router.push(
                                                                      {
                                                                        pathname:
                                                                          '/[slug]/threads/[thread]',
                                                                        query: {
                                                                          slug: blogMeta.slug,
                                                                          thread:
                                                                            typeshare.id,
                                                                        },
                                                                      },
                                                                    )
                                                                  : router.push(
                                                                      {
                                                                        pathname:
                                                                          '/[slug]/posts/[post]',
                                                                        query: {
                                                                          slug: blogMeta.slug,
                                                                          post: typeshare
                                                                            ?.val
                                                                            .preview
                                                                            ?.liveSlug
                                                                            ? typeshare
                                                                                .val
                                                                                .preview
                                                                                .liveSlug
                                                                            : typeshare.id,
                                                                        },
                                                                      },
                                                                    )
                                                              }
                                                            />
                                                          ),
                                                        )}
                                                      </div>

                                                      <div
                                                        className={
                                                          'my-4 flex w-full flex-col items-center justify-center space-y-2 border-t border-gray-200 pt-4 text-sm font-medium text-gray-500'
                                                        }
                                                      >
                                                        {next ? (
                                                          <p>
                                                            Showing{' '}
                                                            {
                                                              blogMeta.displayName
                                                            }
                                                            's {posts.length}{' '}
                                                            most recent
                                                            published posts
                                                          </p>
                                                        ) : (
                                                          <p>
                                                            Showing all of{' '}
                                                            {
                                                              blogMeta.displayName
                                                            }
                                                            's published posts
                                                          </p>
                                                        )}
                                                        {next && (
                                                          <Button
                                                            variant={'outline'}
                                                            size={'mini'}
                                                            click={() =>
                                                              fetchPosts(
                                                                galleryUserID,
                                                                blogMeta.slug,
                                                              )
                                                            }
                                                            disabled={!next}
                                                          >
                                                            Load More
                                                          </Button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  )
                                                }
                                              </div>
                                            </>
                                          ) : null}
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            'w-full space-y-8 antialiased'
                                          }
                                        >
                                          {posts.length === 0 ? (
                                            <div className={'w-full'}>
                                              <EmptyStateSection
                                                icon={
                                                  <EssayIconDetailed
                                                    className={
                                                      'h-12 w-12 text-gray-400'
                                                    }
                                                  />
                                                }
                                                title={'No Published Posts'}
                                                text={`${blogMeta.displayName} hasn't published any posts yet.`}
                                                noCTA={true}
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                'flex w-full flex-col items-center justify-center space-y-8'
                                              }
                                            >
                                              <div
                                                className={
                                                  'grid w-full grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4'
                                                }
                                              >
                                                {posts.map((typeshare, i) => (
                                                  <EssayCard
                                                    key={typeshare.id}
                                                    creatorID={galleryUserID}
                                                    creatorSlug={blogMeta.slug}
                                                    typeshare={typeshare}
                                                    click={() =>
                                                      typeshare?.val?.tweetArray
                                                        ? router.push({
                                                            pathname:
                                                              '/[slug]/threads/[thread]',
                                                            query: {
                                                              slug: blogMeta.slug,
                                                              thread:
                                                                typeshare.id,
                                                            },
                                                          })
                                                        : router.push({
                                                            pathname:
                                                              '/[slug]/posts/[post]',
                                                            query: {
                                                              slug: blogMeta.slug,
                                                              post: typeshare
                                                                ?.val.preview
                                                                ?.liveSlug
                                                                ? typeshare.val
                                                                    .preview
                                                                    .liveSlug
                                                                : typeshare.id,
                                                            },
                                                          })
                                                    }
                                                  />
                                                ))}
                                              </div>

                                              <div
                                                className={
                                                  'my-4 flex w-full flex-col items-center justify-center space-y-2 border-t border-gray-200 pt-4 text-sm font-medium text-gray-500'
                                                }
                                              >
                                                {next ? (
                                                  <p>
                                                    Showing{' '}
                                                    {blogMeta.displayName}'s{' '}
                                                    {posts.length} most recent
                                                    published posts
                                                  </p>
                                                ) : (
                                                  <p>
                                                    Showing all of{' '}
                                                    {blogMeta.displayName}'s
                                                    published posts
                                                  </p>
                                                )}
                                                {next && (
                                                  <Button
                                                    variant={'outline'}
                                                    size={'mini'}
                                                    click={() =>
                                                      fetchPosts(
                                                        galleryUserID,
                                                        blogMeta.slug,
                                                      )
                                                    }
                                                    disabled={!next}
                                                  >
                                                    Load More
                                                  </Button>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}{' '}
                                </>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </main>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={'flex h-screen w-screen items-center justify-center'}
            >
              <div
                className={
                  'mt-20 flex max-w-xl flex-col space-y-4 rounded-2xl border border-gray-100 bg-white p-10 shadow-2xl'
                }
              >
                <div
                  className={
                    'flex flex-col items-center justify-center space-y-4'
                  }
                >
                  <p className={'text-5xl'}>🖼😢</p>
                  <h1
                    className={
                      'text-center text-4xl font-bold tracking-tighter antialiased'
                    }
                  >
                    This gallery doesn't exist yet.
                  </h1>
                  <a
                    href={'/'}
                    className={
                      'cursor-pointer rounded-full bg-indigo-500 px-4 py-2 text-white hover:bg-indigo-600'
                    }
                  >
                    Go Back to Typeshare
                  </a>
                  {/* <div className={"grid grid-cols-6 grid-flow-row"}>
                                            <div style={{ boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)" }} className={"w-12 h-12 rounded-full from-blue-100 to-pink-100 bg-gradient-to-br justify-center items-center text-center flex hover:scale-105 transform duration-200 active:scale-100 hover:shadow-xl cursor-pointer"}><p>🚢</p></div>
                                            <div style={{ boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)" }} className={"w-12 h-12 rounded-full from-orange-100 to-red-100 bg-gradient-to-br justify-center items-center text-center flex hover:scale-105 transform duration-200 active:scale-100 hover:shadow-xl cursor-pointer"}><p>🔥</p></div>
                                            
                                        </div> */}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          className={
            ' flex h-full items-center justify-center bg-gray-100 font-sans antialiased'
          }
        >
          <div
            className={
              'flex flex-col items-center justify-center space-y-16 px-4'
            }
          >
            <div
              className={'flex flex-col items-center justify-center space-y-2'}
            >
              <h1 className={'text-4xl font-bold tracking-tight'}>
                Social Blog not found
              </h1>
              <p className={'text-center text-gray-500'}>
                {
                  'No user with this URL exists. Please check to make sure you have the right URL'
                }
              </p>
            </div>

            <div className={'flex w-full flex-col'}>
              <Button
                click={() => router.push('/')}
                width={'full'}
                className={
                  'my-1 flex w-full transform flex-row items-center justify-center space-x-2 rounded-lg bg-gradient-to-b from-gray-700 to-gray-900 py-3 font-primary font-medium tracking-normal text-white transition-all duration-200 hover:my-0 hover:py-4 active:scale-95 '
                }
              >
                <p>Go Home</p>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Gallery;
