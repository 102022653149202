import { ArrowUpIcon, XIcon } from '@heroicons/react/solid';
import EssayCard from '../../Cards/essayCard';
import { useRouter } from '../../SocialBlogContext';

export default function ThreadCollectionCard({
  edit,
  removeCard,
  click,
  threads,
  userID,
  slug,
  theme,
  cantDelete,
  blogMeta,
}) {
  const router = useRouter();

  const close = (event) => {
    event.stopPropagation();
    removeCard();
  };
  const open = (event) => {
    event.stopPropagation();
    router.push({ pathname: '/[slug]/threads', query: { slug: slug } });
  };

  const formatTS = (ts) => {
    if (ts) {
      var format = {
        id: ts.key,
        val: ts.val(),
      };
      return format;
    } else {
      return null;
    }
  };

  return (
    <div
      onClick={() =>
        edit
          ? null
          : router.push({ pathname: '/[slug]/threads', query: { slug: slug } })
      }
      className={`${
        edit
          ? 'cursor-move'
          : 'transform cursor-pointer duration-200 hover:scale-105 active:scale-100'
      } group  relative h-full w-full overflow-hidden  rounded-3xl bg-white`}
    >
      {/*       <img src={"https://mymind.com/wp-content/uploads/2021/10/Highrest-Artwork-4.jpg"} className={"absolute h-full w-full object-cover"} /> */}

      <div className={'absolute top-20 h-full w-full'}>
        {/*need to validate that user has 3 or more typeshares */}
        {threads.length > 0 ? (
          <>
            <div
              className={`  ${
                threads.length > 0 ? 'block' : 'hidden'
              } relative z-10 w-full -translate-x-4 rotate-6 scale-75 transform rounded-3xl border border-gray-200`}
            >
              {/*  <ThreadCard passedThread={threads[0].val()} creatorMeta={{ displayName: blogMeta.name, image: blogMeta.image }} /> */}
              <EssayCard
                creatorSlug={slug}
                creatorID={userID}
                preview={true}
                socialBlog={true}
                typeshare={formatTS(threads[1])}
                edit={edit}
                click={() => null}
              />
            </div>
            {threads.length > 1 ? (
              <div
                className={` ${
                  threads.length > 1 ? 'block' : 'hidden'
                } absolute top-0 w-full translate-x-4  -translate-y-12 rotate-[8deg] scale-75 transform rounded-3xl border border-gray-200`}
              >
                <EssayCard
                  creatorSlug={slug}
                  creatorID={userID}
                  preview={true}
                  socialBlog={true}
                  typeshare={formatTS(threads[1])}
                  edit={edit}
                  click={() => null}
                />
              </div>
            ) : null}{' '}
          </>
        ) : null}
      </div>

      <div
        className={
          'absolute z-20 flex h-full w-full items-end justify-start bg-gradient-to-t from-white p-6'
        }
      >
        <div
          className={
            'absolute top-2 left-2 flex rounded-full bg-gray-200 bg-opacity-50 py-2 px-3 text-sm font-medium text-gray-700 backdrop-blur-xl backdrop-filter'
          }
        >
          <p>Collection</p>
        </div>

        <p className={'font-primary text-2xl font-semibold text-gray-900'}>
          All Threads
        </p>
      </div>

      <div
        className={`${
          edit ? 'flex' : 'hidden'
        } absolute top-2 right-2 z-20 flex flex-row items-center justify-center space-x-2`}
      >
        <button
          onMouseDown={(event) => open(event)}
          onTouchStart={(event) => open(event)}
          className={` ${
            slug !== null ? 'flex' : 'hidden'
          } rounded-full bg-gray-100 p-2 opacity-0 ring-gray-200 transition-all duration-200 hover:ring-4 group-hover:opacity-100`}
        >
          <ArrowUpIcon
            className={'h-4 w-4 rotate-45 transform text-gray-600'}
          />
        </button>

        <button
          onMouseDown={(event) => close(event)}
          onTouchStart={(event) => close(event)}
          className={
            ' rounded-full bg-gray-100 p-2 text-red-500 opacity-0  ring-red-100 transition-all duration-200 hover:ring-4 group-hover:opacity-100'
          }
        >
          <XIcon className={'h-4 w-4'} />
        </button>
      </div>
    </div>
  );
}
